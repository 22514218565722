.cb4-related-resources{    
    @include paddingTopBottom;
    background-image: $grad3;
    h2 {
        color: color(white);
        text-align: center;
    }
    .underline-link {
        font-weight: bold;

        @media (max-width: theme("screens.lg")) {
            margin-top: $padding--sm;
        }
    }
        
    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: #{$padding--sm};
        @media (max-width: theme("screens.lg")) {
            grid-template-columns: 1fr;
        }

    }
   
    .card {
        background-color:color(white);
        .date > time{
            font-size: 1.3rem;
            color: color(accent);
            text-transform: uppercase;
        }
        .views-field{
            &.views-field-field-resource-teaser-image{
                padding:0 0 25px 0;
                @media (min-width: theme("screens.lg")) {
                    display:block;
                }
                @media (max-width: theme("screens.lg")) {
                    display:none;
                }
            }
            &.views-field-field-resource-teaser-image-1{
                padding:0 0 25px 0;
                @media (min-width: theme("screens.lg")) {
                    display:none;
                }
                @media (max-width: theme("screens.lg")) {
                    display:block;
                    img {
                      object-fit: cover;
                    } 
                }
            }
            &.views-field-field-resource-published-date{
                padding-bottom: 5px;
            }
            &.views-field-view-node{
                padding-bottom: 45px;
                padding-top: 18px;
            }
            &.views-field-title{
                padding-bottom: 12px;
            }
            padding: 0 45px;
            
        }
    }
    .links{ justify-content: center;}    
}

.cb19-icon-list {
	@include paddingTopBottom;

	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		text-align: center;
		padding-bottom: $padding--md;

		img {
			height: 100px;
			width: auto;
			margin-bottom: $padding--xs;
		}

		p.title4 {
			margin: $padding--xs 0;
		}

		a {
			@include underlineLink;
		}
	}

	.card-layout {
		border-bottom: $mainRule;
	}

	.swiper-cont {
		margin-top: $padding--sm;

		@media (max-width: $mobile) {
			margin-bottom: $padding--xs;
		}
	}

	.swiper-btm-bar {
		border-color: color(gray, rules);
	}
}

.cb30-tab-content {
	@include paddingTopBottom;

	h2 {
		@media (max-width: $mobile) {
			text-align: center;
		}
	}

	.col {
		&:nth-of-type(1) {
			width: 600px;
			padding-right: $padding--md;

			@media (max-width: $mobile) {
				width: 100%;
				padding-right: 0;
			}
		}

		&:nth-of-type(2) {
			width: calc(100% - 550px);

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	.top-section {
		.card-layout {
			@media (max-width: $mobile) {
				flex-direction: row;
				justify-content: space-between;
			}
		}

		.card {
			@include paddingAllSidesSm;
			background: color(accent, mist);
			width: calc((100% - #{$padding--xs}) / 2);
			margin-bottom: $padding--xs;

			&:not(:nth-of-type(even)) {
				margin-right: $padding--xs;

				@media (max-width: $mobile) {
					margin-right: 0;
				}
			}

			@media (max-width: $mobile) {
				//width: 100%;
				padding: $padding--xs;
				justify-content: center;
				display: flex;
				flex-direction: column;
			}

			p {
				margin: 0;
				text-align: center;
				color: color(accent, midnight);

				&.eyebrow {
					margin-bottom: $padding--xs;

					@media (max-width: $mobile) {
						margin-bottom: 5px;
					}
				}
			}
		}
	}

	.tab-nav {
		display: flex;
		flex-direction: column;

		&.desktop-tabs {
			border-top: $mainRule;
			
			@media (max-width: $mobile) {
				display: none;
			}
		}

		&.mobile-select {
			@media (min-width: $mobile) {
				display: none;
			}
		}

		.tab-link {
			@include paddingTopBottomSm;
			border-bottom: $mainRule;
			text-align: left;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			transition: color $animationDuration;

			@media (max-width: $mobile) {
				padding: $padding--xs 0;
			}

			.tab-link-wrapper {
				padding-right: 1rem;
			}

			&:after {
				content: "";
				background-image: url("../imgs/icons/blue-arrow.svg");
				background-repeat: no-repeat;
				min-width: 33px;
				height: 21px;
				display: block;
				opacity: 0;
				transition: opacity $animationDuration;

				@media (max-width: $mobile) {
					display: none;
				}
			}

			&.active {
				text-decoration: none;
				color: color(accent); //DESIGNS: GRAD3

				&:after {
					opacity: 1;
				}
			}
		}
	}

	.tab-cont {
		.tab-item {
			@include paddingAllSidesMd;
			border: $mainRule;

			@media (max-width: $mobile) {
				padding: $padding--sm;
			}

			ul {
				@include bullets;
			}
		}
	}
}

.maintenance {
  padding: 20px 20px;
  background: $grad2;
  height: 100vh;

  main {
    margin-top: 5rem;
    h1, p {
      color: #ffffff;
    }
  }
}
.cb17-coe-banner {
	@include paddingTopBottom;
	background-image: $grad2;
	text-align: center;

	@media (max-width: $mobile) {
		text-align: left;
	}

	.grad-offset,
	.desc {
		max-width: 875px;
		display: block;
		margin: $padding--md auto;
	}

	.grad-offset {
		max-width: 875px;
		margin: $padding--md auto;

		@media (max-width: $mobile) {
			padding-left: $padding--xs;

			&:after {
				content: "";
				width: calc(100% + (-1 * $padding--xs));
				left: 0;
			}
		}

		img {
			position: relative;
			width: 100%;
			height: auto;
		}
	}
}

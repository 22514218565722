.cb31-stats {
	@include paddingTopBottom;

	.top {
		@media (min-width: $mobile) {
			padding-bottom: $padding--lg;
		}
	}

	.column-layout {
		@include paddingTopBottomSm;

		&:first-child {
			padding-top: 0;
		}

		.col:nth-of-type(1) {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@media (max-width: $mobile) {
				justify-content: center;
			}
		}

		&:nth-of-type(even) {
			flex-direction: row-reverse;

			@media (max-width: $mobile) {
				flex-direction: column;
			}

			.stat-cont {
				justify-content: flex-start;

				@media (max-width: $mobile) {
					justify-content: center;
				}

				p.stat {
					text-align: left;

					@media (max-width: $mobile) {
						text-align: center;
					}
				}
			}

			.col:nth-of-type(1) {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				@media (max-width: $mobile) {
					justify-content: center;
				}
			}

			.col:nth-of-type(2) p {
				text-align: right;

				@media (max-width: $mobile) {
					text-align: center;
				}
			}
		}

		@media (max-width: $mobile) {
			p {
				text-align: center;
			}
		}

		&:not(:last-child) {
			border-bottom: $largeWeight solid color(accent, arctic);
		}
	}

	.stat-cont {
		position: relative;
		display: flex;
		justify-content: flex-end;

		@media (max-width: $mobile) {
			justify-content: center;
		}

		$start: #4200ed;
		$end: #6f66b1;
		$percent: 10%;

		p.stat {
			font-family: $headlineFont;
			font-size: 13rem;
			line-height: 1;
			color: transparent;
			-webkit-text-stroke: 2px color(accent);
			text-align: right;

			-webkit-background-clip: text;
			//background-image: linear-gradient(90deg, $start 0%, $start $percent, rgb(255, 255, 255) $percent, rgb(255, 255, 255) 100%);

			will-change: background-image;

			@media (max-width: $mobile) {
				font-size: 9rem;
				text-align: center;
			}
		}
	}

  .pdf-link {
    @extend .btn;
    margin: 0 auto;
  }
}

.cb34-video-image-gallery {
	@include paddingTopBottom;

	.tab-section {
		border-top: $largeWeight solid color(accent);
	}

	.tab-cont {
		border-bottom: 1px solid color(black);
	}

	.tab-item {
		.img-crop {
			padding-bottom: calc((570 / 1400) * 100%);
		}

		.container {
			padding: $padding--sm 0;

			@media (max-width: $mobile) {
				padding: $padding--xs 0;
			}

			p:last-child {
				margin: 0;
			}
		}

		.wysiwyg p {
			font-size: 1.3rem;
			line-height: calc(22 / 13);

			@media (max-width: $mobile) {
				font-size: 1.6rem;
				line-height: calc(26 / 16);
			}
		}
	}

	.swiper-section {
		padding-top: $padding--sm;
		padding-bottom: $padding--sm;

		@media (max-width: $mobile) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.container {
		@media (max-width: $mobile) {
			padding: 0;
		}
	}

	.swiper-slide {
		cursor: pointer;
		transition: opacity $animationDuration;

		.img-crop {
			padding-bottom: calc((163 / 320) * 100%);
		}

		.video-link {
			@media (max-width: $mobile) {
				transform: scale(0.5);
			}
		}

		&.active {
			opacity: 0.4;
		}
	}

	.swiper-btm-bar {
		border-top: none;

		//@media (min-width: $mobile) {
			p {
				display: none;
			}
		//}
	}

	.swiper-button-prev,
	.swiper-button-next {
		background: none;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		&:after {
			display: none;
		}

		@media (max-width: $mobile) {
			position: relative;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			width: auto;
			height: auto;
		}
	}

	.swiper-button-prev {
		justify-content: flex-start;

		img {
			transform: rotate(180deg);
		}
	}
}

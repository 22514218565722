/****************************************

TYPOGRPAHY

****************************************/

@mixin h1-font-styles {
	font-family: $headlineFont;
	font-weight: 400;
	font-size: 6rem;
	line-height: calc(63 / 60);

	@include elementSpacing;

	@media (max-width: theme("screens.xl")) {
		//font-size: 7rem;
	}

	@media (max-width: theme("screens.md")) {
		font-size: 3.6rem;
		line-height: calc(39 / 36);
	}
}

h1,
.title1 {
	@include h1-font-styles();
}

@mixin h2-font-styles {
	font-family: $headlineFont;
	font-weight: 400;
	font-size: 4.8rem;
	line-height: calc(48 / 48);

	@include elementSpacing;

	@media (max-width: theme("screens.xl")) {
		//font-size: 5rem;
	}

	@media (max-width: $mobile) {
		font-size: 2.9rem;
		line-height: calc(34 / 29);
	}
}

h2,
.title2 {
	@include h2-font-styles();
}

@mixin h3-font-styles {
	font-family: $headlineFont;
	font-weight: 400;
	font-size: 3.7rem;
	line-height: calc(37 / 37);

	@include elementSpacing;

	@media (max-width: theme("screens.xl")) {
		//font-size: 2.8rem;
	}

	@media (max-width: $mobile) {
		font-size: 2.4rem;
		line-height: calc(27 / 24);
	}
}

h3,
.title3 {
	@include h3-font-styles();
}

@mixin h4-font-styles {
	font-family: $headlineFont;
	font-weight: 400;
	font-size: 2.7rem;
	line-height: calc(36 / 27);
        .field{
            font-size:inherit;
        }

	@include elementSpacing;

	@media (max-width: theme("screens.xl")) {
		//font-size: 2.4rem;
	}

	@media (max-width: $mobile) {
		font-size: 2.1rem;
		line-height: calc(27 / 21);
	}
}

h4,
.title4 {
	@include h4-font-styles();
}

@mixin h5-font-styles {
	font-family: $headlineFont;
	font-size: 1.8rem;
	font-weight: 400;
	line-height: calc(24 / 18);

	@include elementSpacing;

	@media (max-width: $mobile) {
		font-size: 1.8rem;
		line-height: calc(27 / 18);
	}
}

h5,
.title5 {
	@include h5-font-styles();
}

@mixin h6-font-styles {
	font-family: $bodyFont;
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: 0.12em;
	line-height: calc(28 / 18);

	@include elementSpacing;

	@media (max-width: $mobile) {
		font-size: 1.6rem;
		line-height: calc(26 / 16);
	}
}

h6,
.h6,
.title6 {
	@include h6-font-styles();
}

.title7,
p.title {
	font-size: 2.4rem;
	line-height: calc(36 / 24);
}

.small-headline {
	text-transform: uppercase;
	color: color(accent, reflexBlue);
	letter-spacing: 0.15rem;
	font-weight: 600;

	&.gray {
		color: color(accent, darkGray);
	}
}

@mixin p-font-styles {
	// "P4" is the default
	font-weight: 400;
	font-size: 1.6rem;
	line-height: calc(27 / 16);
	@include elementSpacing;

	@media (max-width: theme("screens.xl")) {
		//font-size: 5rem;
	}

	&.p1 {
		font-family: $headlineFont;
		font-size: 5.2rem;
		line-height: calc(64 / 52);

		@media (max-width: $mobile) {
			font-size: 2.3rem;
			line-height: calc(29 / 23);
		}
	}

	&.p2 {
		font-size: 2.4rem;
		line-height: calc(35 / 24);

		@media (max-width: $mobile) {
			font-size: 2rem;
			line-height: calc(33 / 20);
		}
	}

	&.p3 {
		font-size: 1.6rem;
		line-height: calc(28 / 16);
	}

	&.p4 {
		font-size: 1.3rem;
		line-height: calc(22 / 13);

		@media (max-width: $mobile) {
			font-size: 1.6rem;
			line-height: calc(26 / 16);
		}
	}

	&.caption {
		font-size: 13px;
		line-height: calc(22px / 13px);
	}

	sup {
		vertical-align: baseline;
		position: relative;
		top: -0.4em;
	}

	a:not(.btn) {
		color: color(accent);
		text-decoration: underline;
	}

	a:not(.btn)[target="_blank"]:after {
		content: url('../imgs/icons/link-icon.svg');
		margin-left: 0.25rem;
	}
	a.btn[target="_blank"]:before {
		content: url('../imgs/icons/link-icon.svg');
		margin-left: 0.5rem;
		float: right;
		filter: invert(100%);
	}
}

p,
ul,
ol {
	@include p-font-styles;
}

ol {
	padding-left: 1.7em;
}

@mixin bullets {
	@include elementSpacing;

	li {
		position: relative;
		padding: 0 0 0 1.5em;

		&:before {
			content: "";
			position: absolute;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: color(black);
			top: 0.8em;
			left: 0;
		}
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}

	&.bullets {
		position: relative;
		@include bullets;
	}
}

ol {
	list-style: decimal;

	ol {
		list-style: lower-alpha;

		ol {
			list-style: lower-roman;

			ol {
				list-style: upper-alpha;

				ol {
					list-style: upper-roman;
				}
			}
		}
	}
}

.strong,
strong {
	font-weight: 800;
}

.italic {
	font-style: italic;
}

.uppercase {
	text-transform: uppercase;
}

.t-accent {
	color: color(accent);
}

.t-body {
	font-family: $bodyFont;
}

.t-midnight {
	color: color(accent, midnight);
}

.t-sky {
	color: color(accent, sky);
}

.t-peach {
	color: color(accent, peach);
}

.t-smoke {
	color: color(accent, smoke);
}

.t-center {
	text-align: center;
}

.white-text {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	a,
	ul li {
		color: color(white);
	}
}

@mixin eyebrow {
	font-size: 1.2rem;
	line-height: 1;
	letter-spacing: 0.12em;
	font-weight: 500;
	text-transform: uppercase;
}

p.eyebrow {
	@include eyebrow;
}

@mixin eyebrow--lg {
	font-size: 1.8rem;
	line-height: 2.8rem;
	letter-spacing: 0.12em;
	font-weight: 500;
	text-transform: uppercase;

	@media (max-width: $mobile) {
		font-size: 1.6rem;
	}
}

p.eyebrow--lg {
	@include eyebrow--lg;
}

@mixin eyebrow--xs {
	font-size: 1.2rem;
	line-height: 1.4rem;
	letter-spacing: 0.12em;
	font-weight: 300;
	text-transform: uppercase;
	color: color(accent, lilac);
}

p.eyebrow--xs {
	@include eyebrow--xs;
}

figcaption {
	font-size: 1.3rem;
	line-height: 2.2rem;
}

.cb8-title-banner {
  @include paddingTopBottom;
  background-image: $grad2;
  padding-bottom: $padding--md;

  @media (max-width: $mobile) {
    padding-bottom: $padding--sm;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul li {
    color: color(white);
  }

  h1 {
    margin-bottom: 2.8rem;
  }

  .inner {
    max-width: $textWrap--lg;
    margin-bottom: $padding--lg;
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 8rem;
  }
}

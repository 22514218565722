.cb16-full-bleed-banner {
	padding-bottom: $padding--xl;

	@media (max-width: $mobile) {
		padding-bottom: $padding--xl;
	}

	.top {
		padding-top: $padding--xl;

		@media (max-width: $mobile) {
			padding-top: $padding--lg;
		}
	}

	.btm {
		.container {
			border-top: $mainRule;
			margin-top: $padding--xl;
			padding-top: $padding--xs;
		}
	}

	.column-layout {
		flex-direction: row-reverse;
		align-items: center;

		@media (max-width: $mobile) {
			flex-direction: column;
		}

		.col {
			&:nth-child(1) {
				padding-bottom: $padding--sm;

				@media (max-width: $mobile) {
					padding-bottom: $padding--lg;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				p,
				ul li {
					margin-bottom: $padding--sm;
				}

				.breadcrumbs {
					margin-bottom: $padding--lg;

					p {
						margin-bottom: 0;
					}
				}

				p.eyebrow,
				p.eyebrow--lg {
					margin-bottom: $gap;
				}
			}

			&:nth-child(2) {
				.img-crop {
					margin-bottom: calc(-1 * $padding--lg);
				}
			}
		}
	}
  
  .pdf-link {
    @include btnStyle;
  }

	.btn, .pdf-link {
		color: color(black);
		background-color: color(accent, peach);

		&::after {
			background: radial-gradient(circle closest-side, color(accent, smoke), transparent);
		}
	}

	.wysiwyg p:not(.eyebrow) {
		@extend .p2;
	}
}

.page-node-type-resource-event .cb16-full-bleed-banner {
	padding-bottom: 0;
	@media (max-width: $mobile) {
		padding-bottom: 40px;
	}
}

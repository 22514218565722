.cb11-podcast-teaser, .cb11-video-teaser {
	@include paddingTopBottom;
	background-image: $grad5;

	.column-layout {
		align-items: center;

		@media (max-width: $mobile) {
			flex-direction: column-reverse;
		}

		.col {
			&:nth-of-type(1) {
				width: 45%;
				background: color(white);
				padding: $padding--md;

				@media (max-width: $mobile) {
					width: 100%;
					padding: $padding--xs;
				}

				p.eyebrow--lg,
				h2 {
					color: color(accent, midnight);
				}

				.btn {
					margin-top: $padding--md;
				}

        .pdf-link {
          @extend .btn;
        }
			}

			&:nth-of-type(2) {
				width: 55%;

				@media (max-width: $mobile) {
					width: 100%;
				}

				.img-crop {
					padding-bottom: calc((595 / 767) * 100%);

					&:after {
						width: 100%;
						height: 100%;
						content: "";
						display: block;
						z-index: 8;
						position: absolute;
						top: 0;
						left: 0;
						background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 41.01%, rgba(0, 0, 0, 0.9) 100%);
					}

					.description {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						z-index: 9;
						padding: $padding--md;

						@media (max-width: $mobile) {
							padding: $padding--xs;
						}

						p.eyebrow {
							color: color(accent, mist);

							@media (max-width: $mobile) {
								margin-bottom: 5px;
							}
						}

						p.title5 {
							color: color(white);
							margin: 0;

							@media (max-width: $mobile) {
								line-height: 1.2;
							}
						}
					}
				}
			}
		}
	}
}

.cb21-lg-text-callout {
	@include paddingTopBottom;
	text-align: center;

	.title2 {
		margin: $padding--md 0;
		line-height: calc(64 / 52);

		p {
                    &:not(.wysiwyg-title){
                        font-size: inherit;
                    }
                    color: inherit;
                    line-height: inherit;
		}

		span.blue-highlight {
			@include articTextHighlight;
		}
	}
	.links.single-btn a:not(:last-child) {
		margin-right: 2.75rem;
	}
}

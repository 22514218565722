.footnotes {
	.accordion-section {
		border-bottom: $mainRule;
	}

	.accordion-layout .accordion-item {
		border-top: none;
		padding: 0;

		.accordion-header {
			padding: $padding--md 0;

			@media (max-width: $mobile) {
				padding: $padding--xs 0;
			}
		}

		&.open {
			.accordion-header {
				padding-bottom: $padding--xs;
			}

			.accordion-details {
				padding: $padding--xs 0 $padding--md;
			}
		}
	}

	.card-layout {
		justify-content: flex-start;

		.card {
			width: calc((100% - calc(#{$gap} * 12)) / 4);

			@media (max-width: theme("screens.md")) {
				width: 100%;
				margin-bottom: calc(#{$gap} * 4);
			}

			&:not(:nth-of-type(4n + 4)) {
				margin-right: calc(#{$gap} * 4);

				@media (max-width: theme("screens.md")) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

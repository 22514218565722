/****************************************

VARIABLES

****************************************/
@import "_functions";

// MSC
$animationDuration: 0.5s;

//fonts
$headlineFont: "Cambon-Demi", serif;
$bodyFont: "Graphik", sans-serif;

// break points
// use tailwind variables
// theme("screens.sm") : 640px
// theme("screens.md") : 768px
// theme("screens.lg") : 1024px
// theme("screens.xl") : 1280px
// theme("screens.2xl") : 1536px
$mobile: theme("screens.lg");
$xLarge: theme("screens.xl");
$tablet: 1200px;

//Spacing
$padding--xs: theme("spacing.7"); // 1.75rem
$padding--sm: theme("spacing.14"); // 3.5rem
$padding--md: theme("spacing.16"); // 4rem
$padding--lg: theme("spacing.32"); // 8rem
$padding--xl: theme("spacing.56"); // 14rem
$paddingMain: $padding--sm;
$paddingMainMobile: 2rem;
$gap: theme("spacing.4");

$elementSpacingDesktop: $padding--xs;
@mixin elementSpacing {
	margin-bottom: $elementSpacingDesktop;
}

@mixin paddingAllSides {
	padding: $padding--lg;

	@media (max-width: theme("screens.md")) {
		padding: $paddingMainMobile;
	}
}

@mixin paddingAllSidesSm {
	padding: $padding--sm;

	@media (max-width: theme("screens.md")) {
		//padding: $paddingMainMobile;
	}
}

@mixin paddingTopBottomXs {
	padding: $padding--xs 0;
}

@mixin paddingAllSidesXs {
	padding: $padding--xs;
}

@mixin paddingAllSidesMd {
	padding: $padding--md;
}

@mixin paddingTopBottom {
	padding: $padding--xl 0;

	@media (max-width: theme("screens.md")) {
		padding: $padding--lg 0;
	}
}

@mixin paddingTopBottomMd {
	padding: $padding--md 0;

	@media (max-width: theme("screens.md")) {
		//padding: $paddingMainMobile 0;
	}
}
@mixin paddingTopBottomLg {
	padding: $padding--lg 0;

	@media (max-width: theme("screens.md")) {
		//padding: $paddingMainMobile 0;
	}
}

@mixin paddingTopBottomSm {
	padding: $padding--sm 0;

	@media (max-width: theme("screens.md")) {
		//padding: $paddingMainMobile 0;
	}
}

@mixin paddingTopBottomXs {
	padding: $padding--xs 0;
}

//sizes
$textWrap--sm: 300px;
$textWrap--md: 450px;
$textWrap--lg: 700px;
$textWrap--xl: 1000px;

//colors
$colors: (
	white: (
		base: #fff,
		transparent: rgba(255, 255, 255, 0.75),
	),
	black: (
		base: #18181e,
	),
	gray: (
		base: #747487,
		rules: #e0e0e0,
	),
	accent: (
		base: #4200ed,
		reflexBlue: #4200ed,
		midnight: #240066,
		red: #dc3c46,
		lightRed: #feeeef,
		peach: #ff8d7b,
		lilac: #6f66b1,
		mist: #cfcfff,
		lightMist: #ececff,
		smoke: #e5dbe7,
		charcoal: #18181e,
		lightGray: #e0e0e0,
		darkGray: #747487,
		sky: #0094ff,
		neutral: #394452,
		arctic: #ddf3ff,
		calendarBlue: #5142f0,
	),
);

$grad2: linear-gradient(90deg, #240066 0%, #4200ed 100%);
$grad2Reverse: linear-gradient(90deg, #4200ed 0%, #240066 100%);
$grad3: linear-gradient(90deg, #4200ed 0%, #6f66b1 100%);
$grad4: linear-gradient(90deg, #cfcfff 0%, #e5dbe7 100%);
$grad5: linear-gradient(90deg, #cfcfff 0%, #ddf3ff 100%);
$grad5Reverse: linear-gradient(90deg, #ddf3ff 0%, #cfcfff 100%);

// rules
$mainRule: 1px solid color(gray, rules);
$largeWeight: 7px;

.ab9-strategic-plan {
    //  @include paddingTopBottom;    

    // Color spec
    $color-charcoal: #18181E; // $color-text-light
    $color-arctic: #DDF3FF;   // uses $color-text-dark
    $color-midnight: #240066; // uses $color-text-light

    $color-text-light: #ffffff;
    $color-text-dark: $color-charcoal;

    $color-highlight-dark: #4200ED; // for $color-text-light
    $color-highlight-light: $color-text-light; // for $color-text-dark
 
    transition: background-color 1s ease;
    // transition: color 1s ease;
    
    span.highlight {
        @include articTextHighlight;
    }
    // TODO: Abstract out into SASS fn?
    &.color-charcoal {
        background-color: $color-charcoal;

        h2, p, .stats-container, .stats-container h4, .footnotes {
            color: $color-text-light;
        }
        
        span.highlight {
            background-image: linear-gradient(transparent, transparent), linear-gradient($color-highlight-dark, $color-highlight-dark);
        }

        .footnotes .footnotes-header button.expand-footnotes, 
        .footnotes .footnotes-header button.expand-footnotes::after {
            background: $color-text-light;
        }
    }
    &.color-arctic {
        background-color: $color-arctic;

        h2, p, .stats-container, .stats-container h4, .footnotes {
            color: $color-text-dark;
        }

        span.highlight {
            background-image: linear-gradient(transparent, transparent), linear-gradient($color-highlight-light, $color-highlight-light);
        }
    }
    &.color-midnight {
        background-color: $color-midnight;        

        h2, p, .stats-container, .stats-container h4, .footnotes {
            color: $color-text-light;
        }

        span.highlight {
            background-image: linear-gradient(transparent, transparent), linear-gradient($color-highlight-dark, $color-highlight-dark);
        }

        .footnotes .footnotes-header button.expand-footnotes, 
        .footnotes .footnotes-header button.expand-footnotes::after {
            background: $color-text-light;
        }
    }

    section {        
        font-family: $headlineFont;

        p {
            @media (max-width: $mobile) {
                font-size: 1.8rem;
                line-height: 2.7rem;
            }

            font-size: 2.7rem;
            line-height: 3.8rem;
        }

        h2 {
            @media (max-width: $mobile) {
                font-size: 3.6rem;
                line-height: 3.9rem;
                margin-bottom: 4rem;
                width: 80%;
            }

            width: 60rem;
            margin-bottom: 8rem;
            padding-top: 24px;
        }

        &.strategic-plan {            
            padding-top: 12rem;
            .container__content {
                margin-top: -4rem;
                padding: 0 10.5rem 10.5rem 10.5rem;
                
                @media (max-width: $mobile) {                    
                    padding: 0 2rem 2rem 2rem;
                }
            }
        }

        // Column Adjustment
        .column-layout {
            @media (max-width: $mobile) {
                margin-bottom: 4rem;
            }

            justify-content: start;
            margin-bottom: 9rem;
        }      

        div.strategic-plan-number-box {
            @media (max-width: $mobile) {
                margin-left: -2rem;
                padding: 2rem 2rem 2rem 4rem;
            }

            background: linear-gradient(111.34deg, #210063 20.2%, #3A00CC 100%);
            color: white;
            font-family: $bodyFont;  
            text-align: center;   
                        
            width: 100%;
            margin-left: -12rem;            
            padding: 2rem 2rem 2rem 10rem;

            div.number {
                @media (max-width: $mobile) {
                    font-size: 9.0rem;
                    line-height: 9.0rem;
                }
    
                display: block;
                font-weight: 600;
                font-size: 18rem;
                line-height: 18rem; 
                font-family: $headlineFont;    
            }

            div.subHead {
                @media (max-width: $mobile) {
                    font-size: 1.6rem;
                    line-height: 2.6rem;
                    letter-spacing: 0.5rem;
                }

                font-size: 1.8rem;
                line-height: 2.8rem;
                letter-spacing: 0.325rem;
                text-transform: uppercase;   
            }
        }

        
    }

    .stats-container {

        h6 {
            text-align: center;
            font-family: $bodyFont; 
            margin-bottom: 4rem;
            text-transform: uppercase;
        }

        .stats {
            text-align: center;

            > :nth-child(2), :nth-child(3), :nth-child(4) {
                @media (max-width: $mobile) {
                    border: none;
                }

                border-left: 1px solid;
            }
            
            h4 {
                font-size: 6.0rem;
                line-height: 6.3rem;
                margin-bottom: 1.5rem;
            }

            p {
                @media (max-width: $mobile) {
                    padding-top: 0;
                }

                color: #747487;
                font-family: $bodyFont; 
                font-size: 1.6rem;
                line-height: 2.7rem;
                padding: 2.0rem;
            }
        }
    }

    .footnotes {
        border-top: 1px solid white;
        font-family: $bodyFont; 
        padding-top: 2rem;
        
        
        .footnotes-header {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            button.expand-footnotes {
                width: 18px;
                height: 2px;
                display: block;
                background: #18181e;
                -ms-flex: none;
                flex: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: center;
                justify-content: center;
                -ms-flex-align: center;
                align-items: center;

                &::after {
                    content: "";
                    width: 2px;
                    height: 18px;
                    display: block;
                    background: #18181e;
                    transition: opacity .5s;
                }
                &.open::after {
                    opacity: 0;
                }
            }
        }

        p {
            font-family: $bodyFont; 
            font-size: 1.3rem;
            line-height: 2.2rem;
            padding-right: 2.6rem;
        }

        .title {
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.2rem;
            text-transform: uppercase;
        }


        // Expansion panel
        .expand {
            padding-top: 5rem;

            //closed by default
            display: none;
        }

    }

    // Marquee implementation
	@keyframes scroll {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(calc(-100% - var(--gap)));
		}
	}

	.marquee {
		--gap: 1rem;
		display: flex;
		overflow: hidden;
		user-select: none;
		gap: var(--gap);
		
		.marquee__content {
			flex-shrink: 0;
			display: flex;			
			gap: var(--gap);		
			animation: scroll 10s linear infinite;	
            
            img {
                height: 13rem;
                @media (max-width: $mobile) {
                    height: 7.6rem;
                }
            }

			@media (max-width: $mobile) {			
				padding: 1rem 0;
			}			
		}
	}

}


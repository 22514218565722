table.table-section {
	@include p-font-styles();

	caption {
		color: inherit;
	}

	tr {
		width: 100%;
                background: none;
                padding:0;
                border:none;
		@media (min-width: $mobile) {
			display: flex;
		}

		td,
		th {
			padding: 2rem;
			width: 100%;
			text-align: left;
			border-color: color(gray, rules);
                        background: none;
			&:last-child {
				border-right: none;
			}
		}

		th {
			font-weight: inherit;
			border-width: 0 1px 0 0;
                        background: none;
		}

		td {
			border-width: 1px 1px 0 0;
		}
	}
}

.table-responsive {
	border: 0;
}

/****************************************

ACCENTS

****************************************/

// Top borders
.topbar--lilac {
	border-top: 5px solid color(accent, lilac);
}

.topbar--Continuing {
	border-top: 5px solid color(accent, sky);
}

.topbar--Masters {
	border-top: 5px solid color(accent, peach);
}

.faded-bg-img {
	background-size: cover;
	background-position: center center;
	position: relative;

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: 1;
	}

	> * {
		z-index: 9;
		position: relative;
	}
}

.blue-bg-img {
	position: relative;
	background: #3000ad;

	.bg-img {
		background-size: cover;
		background-position: center center;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		//background-blend-mode: multiply;
		//background-blend-mode: overlay;
		mix-blend-mode: multiply;
		opacity: 0.5;
		z-index: 1;
	}

	> * {
		z-index: 9;
		position: relative;
	}
}

.grad-offset {
	position: relative;

	img {
		z-index: 2;
	}

	&:after {
		content: "";
		width: calc(100% + (1 * $padding--xs));
		height: calc(100% + (2 * $padding--xs));
		display: block;
		position: absolute;
		bottom: 0;
		left: $padding--xs;
		background-image: $grad3;
		z-index: 1;

		@media (max-width: $mobile) {
			width: calc(100% + (0 * $padding--xs));
			height: calc(100% + (1 * $padding--xs));
		}
	}

	&.left-offset {
		&:after {
			left: calc(-2 * $padding--xs);
			height: (100%);
			top: calc(-1 * $padding--xs);
		}
	}
}

.white-offset {
	position: relative;

	img {
		z-index: 9;
	}

	&:after {
		content: "";
		width: calc(100% + (1 * $padding--xs));
		height: calc(100% + (2 * $padding--xs));
		display: block;
		position: absolute;
		bottom: 0;
		left: calc(-2 * $padding--xs);
		background-color: #fff;
		z-index: 1;
	}

	&.inset {
		&:after {
			content: "";
			width: calc(100% + (-1 * $padding--xs));
			height: calc(100% + (-2 * $padding--xs));
			bottom: calc(4 * $padding--xs);
			left: calc(-2 * $padding--xs);
		}
	}

	@media (max-width: $mobile) {
		margin-left: $padding--xs;

		&:after {
			width: calc(100% + (-1 * $padding--xs));
			height: calc(100% + (1 * $padding--xs));
			left: calc(-1 * $padding--xs);
		}

		&.inset:after {
			left: calc(-1 * $padding--xs);
			height: calc(100% + (-3 * $padding--xs));
		}

		img {
			width: calc(100% - $padding--xs);
		}
	}
}

@mixin articTextHighlight() {
	position: relative;
	z-index: 9;
	background-image: linear-gradient(transparent, transparent), linear-gradient(color(accent, arctic), color(accent, arctic));
	background-size: 100% 20px, 100%;
	margin-bottom: 1em;
	background-repeat: no-repeat, no-repeat;
	background-position: top left, top .45em left 0px;
	padding-bottom: 5px;
}

@mixin gradUnderline {
	&:after {
		content: "";
		width: 100%;
		height: 11px;
		background-image: $grad4;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

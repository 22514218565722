@mixin fieldStyle {
	display: flex;
	font-size: 1.4rem;

	label {
		font-size: 1.4rem;
		font-weight: 400;
		margin-bottom: $gap;

		span {
			//	color: color(accent, red);
		}
	}

	//input,
	select,
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	textarea {
		border: 1px solid color(black);
		border-radius: 2px;
		font-size: 1.4rem;
		padding: 10px 20px;
		min-height: 37px;
	}

	input[type="file"] {
		width: 100%;
	}

	input[readonly] {
		outline: none;
		background-color: color(accent, lightGray);
	}

	p {
		margin: $gap 0 0 0;

		&.disclaimer {
			font-style: italic;
		}
	}
}

.field {
	@include fieldStyle;
	@include elementSpacing;

	flex-direction: column;
}

.inline-field {
	@include fieldStyle;

	flex-direction: row;
	align-items: center;
	gap: 1rem;

	.display-field {
		margin-bottom: $gap;
	}
}

.req-star,
.field-error,
.red-text {
	color: color(accent, red);
}

ul.checkboxes {
	font-size: 1.4rem;

	li {
		position: relative;
	}

	input[type="checkbox"] {
		appearance: none;

		&:after {
			content: " ";
			display: block;
			width: 15px;
			height: 15px;
			border: 1px solid color(black);
			border-radius: 2px;
			position: absolute;
			top: 2px;
			background-color: white;
			cursor: pointer;
		}

		&:checked:after {
			background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='1.5' fill='%234200ED'/%3e%3cmask id='mask0_1503_2219' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='2' y='4' width='14' height='10'%3e%3cpath d='M6.59422 11.9063L3.99172 9.30379C3.8516 9.16335 3.66136 9.08442 3.46297 9.08442C3.26458 9.08442 3.07434 9.16335 2.93422 9.30379C2.64172 9.59629 2.64172 10.0688 2.93422 10.3613L6.06922 13.4963C6.36172 13.7888 6.83422 13.7888 7.12672 13.4963L15.0617 5.56129C15.3542 5.26879 15.3542 4.79629 15.0617 4.50379C14.9216 4.36335 14.7314 4.28442 14.533 4.28442C14.3346 4.28442 14.1443 4.36335 14.0042 4.50379L6.59422 11.9063Z' fill='black'/%3e%3c/mask%3e%3cg mask='url(%23mask0_1503_2219)'%3e%3crect width='18' height='18' fill='white'/%3e%3c/g%3e%3c/svg%3e");
			background-position: -1px -1px;
			background-repeat: no-repeat;
			background-size: 16px 16px;
		}
	}

	label {
		margin-left: 2rem;
	}
}

ul.radios {
	font-size: 1.4rem;

	li {
		position: relative;
	}

	input[type="radio"] {
		appearance: none;

		&:after {
			content: " ";
			display: block;
			width: 15px;
			height: 15px;
			border: 1px solid color(black);
			border-radius: 999px;
			position: absolute;
			top: 2px;
			background-color: white;
			cursor: pointer;
		}

		&:checked:after {
			background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='8.5' fill='white'/%3e%3ccircle cx='9' cy='9' r='4.5' fill='%234200ED'/%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='8.5' stroke='%2318181E'/%3e%3c/svg%3e");
			background-position: -1px -1px;
			background-repeat: no-repeat;
			background-size: 15px 15px;
		}
	}

	label {
		margin-left: 2rem;
	}
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3e%3cpath fill='%234200ED' d='M5 6L0 0H10L5 6Z'/%3e%3c/svg%3e");
  background-size: 10px 6px;
  background-position: right 1rem center;
  background-repeat: no-repeat;
  appearance: none;
  border: 1px solid color(black);
  border-radius: 2px;
  font-size: 1.3rem;
}

//hubspot
.hbspt-form {
	label {
		margin: 0;
	}

	.hs-error-msg {
		font-weight: 400;
	}

	ul[role="checkbox"] {
		@extend .checkboxes;

		input[type="checkbox"]::after {
			top: 6.5px;
		}
	}

	.hs-form-checkbox {
		label {
			display: flex;
			align-items: center;

			@media (max-width: $mobile) {
				align-items: flex-start;
				text-align: left;
			}
		}

		input {
			margin-right: 10px;

			@media (max-width: $mobile) {
				margin-top: 5px;
			}
		}
	}

	.hs-fieldtype-checkbox {
		ul.inputs-list.multi-container {
			display: flex;
			flex-wrap: wrap;

			li {
				width: 50%;

				label {
					@include p-font-styles;
					margin: 0;
					display: flex;
					align-items: center;
					justify-content: flex-start;

					input {
						width: 20px;
					}
				}
			}
		}
	}

	.hs-fieldtype-text,
	.hs-fieldtype-select,
	.hs-fieldtype-phonenumber,
	.hs_error_rollup,
	.hs-fieldtype-checkbox {
		label {
			@include eyebrow;
			text-transform: inherit;
			letter-spacing: inherit;
			margin-bottom: 5px;
		}
	}

	.hs_error_rollup {
		font-size: 1.4rem;
		margin-bottom: 1.75rem;
	}

	.hs_submit {
		input[type="submit"] {
			@include btnStyle;
			color: color(accent, midnight);
			background-color: color(accent, peach);
			margin: auto;

			// ::after cannot be applied to input elements so this is the next best thing
			// transition causes flashing when paired with gradient
			transition: none;
			&:hover {
				color: color(accent, midnight);
				background: linear-gradient(135deg, color(accent, peach), color(accent, smoke));
			}
		}
	}

	select,
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	textarea {
		width: 100% !important; // important tag required for style override
	}

	.input {
		margin: 0 !important; // important tag required for style override
	}

	fieldset {
		max-width: 100% !important; // important tag required for style override

		&.form-columns-2 {
			display: flex;
			justify-content: space-between;

			@media (max-width: theme("screens.md")) {
				flex-direction: column;
			}

			> * {
				width: calc((100% - $padding--xs) / 2) !important; // important tag required for style override

				@media (max-width: theme("screens.md")) {
					width: 100% !important; // important tag required for style override
					margin-bottom: $padding--sm;
				}
			}
		}
	}

	// errors
	input.invalid, input.error {
		border: 1px solid color(accent, red);
	}

	.hs-error-msgs, .hs-error-msg {
		color: color(accent, red);
	}
}

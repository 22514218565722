.cb28-program-tabs {
	@include paddingTopBottom;
	background: color(white);

	.main-layout {
		> .col {
			&:nth-of-type(1) {
				width: 325px;
				padding-right: $padding--lg;

				* {
					margin-bottom: $padding--xs;
				}
				svg {
					margin-bottom: 0;
				}

				@media (max-width: $mobile) {
					width: 100%;
				}

				h2 {
					margin-bottom: $padding--md;
				}
			}

			&:nth-of-type(2) {
				width: calc(100% - 325px);

				@media (max-width: $mobile) {
					width: 100%;
				}
			}
		}
	}

	.tab-nav {
		border-bottom: 1px solid color(black);

		@media (max-width: $mobile) {
			border-bottom: none;
			align-items: flex-start;
		}

		.tab-link {
			@include eyebrow;
			position: relative;
			z-index: 8;
			padding: $padding--xs;

			@media (max-width: $mobile) {
				width: auto;
				background: #f2f2f2;
				padding: calc($padding--xs/2) $padding--xs;

				&:not(:last-child) {
					margin-bottom: 8px;
				}
			}

			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 100%;
				width: 100%;
				z-index: -1;
				opacity: 0;
				background-image: $grad5;
				transition: opacity $animationDuration;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 5px;
				background: color(accent);
				width: 100%;
				z-index: 9;
				opacity: 0;
				transition: opacity $animationDuration;
			}

			&.active {
				text-decoration: none !important; // important tag required for style override

				&:before {
					opacity: 1;
				}

				&:hover {
					&:after {
						display: none;
					}
				}
			}

			&:hover {
				&:after {
					opacity: 1;
				}
			}
		}
	}

	.tab-item {
		padding: $padding--md 0 0;

		@media (max-width: $mobile) {
			padding-bottom: $padding--md;
		}

		p strong {
			font-weight: 500;
		}

		.col {
			&:nth-of-type(2) {
				position: relative;

				img {
					width: 100%;
					height: auto;
					position: relative;
					z-index: 0;

					@media (max-width: $mobile) {
						margin-top: $padding--md;
					}
				}
			}

			&.solo {
				width: 100%;
			}
		}
	}

	.image-wrapper {
		position: relative;
	}

	.caption {
		width: 70%;
		position: absolute;
		bottom: calc(-1 * $padding--sm);
		left: 0;
		padding: $padding--xs;
		z-index: 9;
		background: color(white);
		font-size: 1.2rem;
		display: flex;
		align-items: stretch;

		@media (max-width: $mobile) {
			position: relative;
			bottom: auto;
			left: auto;
			width: 100%;
			padding: $padding--xs $padding--sm;
		}

		&:before {
			width: 1px;
			height: auto;
			display: block;
			content: "";
			flex: none;
			margin-left: calc(-0.5 * $padding--xs);
			margin-right: calc(0.5 * $padding--xs);
			background: color(accent, red);
		}
	}

	.desktop .links {
		flex-direction: column;
		align-items: flex-start;
		a {
			z-index: 8;
		}
	}
}

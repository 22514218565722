.cb24-form {
	@include paddingTopBottom;
	background-image: $grad5;
	.column-layout {
            .col {
                &:nth-of-type(1) {
                    max-width: 40%;	
                    padding-right: $padding--lg;

                    @media (max-width: $mobile) {
                        max-width: 100%;
                        padding-right: 0;
                        margin-bottom: $padding--sm;
                    }
                }

                &:nth-of-type(2) {
                    width: 700px;

                    @media (max-width: $mobile) {
                        width: 100%;
                    }
                }
            }
	}

	.form.hidden {
		display: none;
	}

	.thank-you {
		display: flex;
		justify-content: center;

		&.hidden {
			display: none;
		}

		.inner {
			max-width: 450px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				margin-bottom: $padding--sm;
			}

			p {
				text-align: center;
			}
		}
	}
    // CB24-A specific styles    
    &.cb24a{
       @include paddingTopBottomLg;
       .container{
            padding:0 2rem !important; //This is bad form but the specificity coming from cb15 is too much to easily overcome
        } 
        .column-layout {
            display:block;
            .col {
               &:nth-of-type(1) {
                    max-width: 100%;
                     width: 100%;
                    padding-right: 0;
                    margin-bottom: $padding--sm;
                }

                &:nth-of-type(2) {
                    width: 100%;
                    padding-left: 0;
                }
            }
	}
    }   
}

.ab7-directory {
	@include paddingTopBottom;

	.views-infinite-scroll-content-wrapper {
		@extend .card-layout;
		width: 100%;
    height: 100%;
	}

  &.white {
    // background-color: color(base, white);
  }

  &.arctic {
    background-color: color(accent, arctic);
  }

	.form-inline, .flex-wrap  {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: $padding--md;

		@media (max-width: $mobile) {
			flex-direction: column;
		}

		.form-item, .js-form-item {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			width: 100%;
			padding-right: $padding--xs;

			@media (max-width: $mobile) {
				padding-right: 0;
				margin-bottom: 1rem;
			}

			&.form-item-field-bio-departments-target-id {
				display: none;
			}

			label {
				@include eyebrow;
				letter-spacing: inherit;
				text-transform: inherit;
				margin-bottom: 5px;
			}

			input,
			select,
			.select-wrapper {
				width: 100%;
			}
		}

		.form-actions {
			display: flex;
			align-items: center;
			padding-top: 12px;
			gap: 2rem;

			@media (max-width: $mobile) {
				flex-direction: column;
				width: 100%;
			}

			.btn {
				.icon {
					display: none;
				}

				&[data-drupal-selector="edit-reset"] {
					background: none;
					color: color(black);
					padding: 0;
					margin-right: 0;
					border-radius: 0;
					border: none;
					align-items: inherit;
					overflow: inherit;
					display: block;

					&::after {
						top: auto;
						transform: none;
					}

					@include underlineLink;

					@media (max-width: $mobile) {
						width: auto;
					}
				}
			}
		}
	}

	.contextual-links {
		display: none;
	}

	.pager__item {
		.button {
			@include btnStyle;
			margin: 0 auto;
			display: table;
			width: auto;

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	.card {
		display: flex;
		align-items: flex-start;
    
    &:nth-last-child(2),
    &:last-child {
      margin-bottom: 0;
    }

		@media (max-width: $mobile) {
			flex-direction: column;
		}

		.headshot {
			width: 35%;
			position: relative;
			@include gradUnderline;

			@media (max-width: $mobile) {
				padding-bottom: 10px;
				width: 100%;
				margin-bottom: $padding--xs;
			}
		}

		.inner {
			width: 65%;
			padding-left: $padding--sm;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			@media (max-width: $mobile) {
				width: 100%;
				padding-left: 0;
				text-align: left;
			}

			p.title4 {
				margin-top: 0;
			}

			p.eyebrow {
				text-transform: inherit;
				line-height: calc(22 / 16);
				font-size: 1.6rem;
				letter-spacing: inherit;
			}

			.linkedin {
				display: flex;
				align-items: center;

				img {
					margin-left: 5px;
				}
			}

			.underline-link {
				font-size: 1.4rem;
				img {
					display: inline;
					padding: 0 5px 0 5px;
				}
			}

			p,
			a {
				margin: 0 0 5px 0;
			}

			a {
				margin-bottom: 10px;
			}

			p.eyebrow {
				margin-bottom: $padding--xs;
			}
		}

		&:nth-of-type(n+2) {
			// Make columns with 1 card left-aligned if not the only column
			margin-left: 0 !important; // important tag required for style override
		}
	}
}

.cb18-subnav.jump-links{
  background-color: color(white, base);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.0625);
  z-index: 999;
  position: sticky;
  
  &.hide {
    display: none;
  }
  
  .desktop {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: $mobile) {
      display: none;
    }

    .container--lg {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: $gap;
    }

    a {
      font-size: 1.4rem;
      padding: 0 1rem 20px;
      font-weight: 500;
      
      &.active {
        border-bottom: 4px solid color(accent, base);
        padding-bottom: 16px;
      }
    }
  }

  .mobile {
    display: none;

    @media (max-width: $mobile) {
      display: flex;
      padding: 1rem 2rem;
      gap: $gap;
      align-items: center;
      height: 60px;
    }

    label {
      font-weight: 500;
      font-size: 1.4rem;
    }

    select {
      padding: 1rem;
      background-color: color(white, base);
      border: 1px solid black;
      flex-grow: 1;
    }
  }
}

// Below code is used to overwride admin menu styles
// only on CoE pages, due to interference w/ subnav
.user-logged-in.page-node-type-coe-page {
  .admin {
    margin-top: 60px;
  }
}
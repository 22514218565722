.cb27-program-banner {
	min-height: 750px;
	display: flex;
	align-items: stretch;
	z-index: 100;

	@media (max-width: $mobile) {
		margin-bottom: 0;
	}

	.full.faded-bg-img {
		@media (max-width: $mobile) {
			background-image: none !important;

			&:before {
				display: none;
			}
		}
	}

	.container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 100%;
	}

	.inner {
		max-width: 700px;
		margin-bottom: $padding--md;

		@media (max-width: $mobile) {
			background-image: $grad2;
			padding: $padding--lg $padding--xs $padding--md;
			margin-bottom: 0;
			width: 100vw;
			margin-left: calc(-1 * $padding--xs);

			h1 {
				margin-bottom: $padding--md;
			}
		}
	}

	.details {
		background: color(white);
		padding: $padding--sm $padding--xs;
		border-top: 15px solid color(accent, lilac);
		margin-bottom: calc(-1 * $padding--lg);

		@media (max-width: $mobile) {
			border-top: none;
			box-shadow: none;
			margin-bottom: 0;
			padding-top: $padding--xs;
		}

		.col {
			padding: 0 $padding--xs;

			@media (max-width: $mobile) {
				&:not(:first-child) {
					padding-bottom: $padding--sm;
				}
			}
			
			p.eyebrow {
				letter-spacing: 0.03em;
			}

			@media (max-width: $mobile) {
				padding: $padding--xs;
				text-align: center;

				p:last-of-type {
					margin-bottom: 0;
				}
			}

			p.p4 .title4 {
				width: 100%;
				display: block;
				margin: 0;
			}

			.tool-tip {
				display: flex;
				align-items: center;

				@media (max-width: $mobile) {
					justify-content: center;
				}

				.icon {
					width: 15px;
					height: 15px;
					font-size: 1rem;
				}

				.content {
					top: 20px;

					@media (max-width: $mobile) {
						width: 100%;
						left: 0;
					}

					p:not(.wysiwyg-title) {
						@extend .p4;
					}
				}
			}

			&.tuition {
				.eyebrow {
					margin-bottom: $padding--xs;
				}

				.wysiwyg {
					p:not(.wysiwyg-title) {
						@extend .p4;
						margin-bottom: 0.5rem;
					}

					.wysiwyg-title {
						margin-top: 0;
					}
				}
			}

			.pdf-link, .btn {
				@include btnStyle;

				&:not(:last-child) {
				margin-bottom: $padding--xs;
				}
			}

			&:not(:last-child) {
				border-right: $mainRule;

				@media (max-width: $mobile) {
					border-right: none;
					border-bottom: $mainRule;
				}
			}
		}

		.format-title {
			margin-bottom: 1.75rem;

			p.eyebrow {
				margin-bottom: 0;
				margin-right: 5px;
			}	
		}
	}

	[data-modal="request-info"] {
		p {
			text-align: center;
			max-width: 500px;
			margin: 0 auto $padding--xs !important;

			&.title1 {
				max-width: 100%;
			}
		}
	}
}

.mastersdegreesgraduateprograms .cb27-program-banner .details {
	border-color: color(accent, peach);
}

.mastersdegreeprogram .cb27-program-banner .details {
	border-color: color(accent, peach);
}

.certificateprogram .cb27-program-banner .details {
	border-color: color(accent, peach);
}

.continuingeducationcourses .cb27-program-banner .details {
	border-color: color(accent, sky);
}

.continuingeducation .cb27-program-banner .details {
	border-color: color(accent, sky);
}

.consumerfinancialeducation .cb27-program-banner .details {
	border-color: color(accent, sky);
}

.cb33-testimonial-slider {
	@include paddingTopBottom;
	background-image: $grad5;
        &.nogradient{
            background-image:none;
            padding:0;
        }
	.quote {
		padding-bottom: 4.5rem;

		img {
			margin: auto;
			width: 5.9rem;

			@media (max-width: $mobile) {
				width: 3.6rem;
			}
		}
	}

	.title3 {
		font-style: italic;

		p {
			@include h3-font-styles();
		}
	}

	.swiper-slide {
		text-align: center;
		padding-bottom: $padding--lg;

		p {
			color: color(accent, midnight);
		}

		img.logo, .logo img {
			margin: $padding--sm auto 0;
			display: block;

			@media (max-width: $mobile) {
				margin: 0 auto $padding--xs;
			}
		}
	}

	.column-layout {
		@media (max-width: $mobile) {
			flex-direction: column-reverse;
		}

		.col {
			&:nth-of-type(1) {
				width: 475px;

				@media (max-width: $mobile) {
					width: 100%;
					margin-top: 4.5rem;
					justify-content: flex-start;
				}

				&.with-btn {
					position: relative;

					$insetW: 200px;
					$insetH: 90px;
					.white-offset {
						@media (min-width: $mobile) {
							clip-path: polygon(-50% -50%, 100% -50%, 100% calc(100% - $insetH), calc(100% - $insetW) calc(100% - $insetH), calc(100% - $insetW) 100%, -50% 100%);
						}
					}

					.overlay-btn {
						@media (min-width: $mobile) {
							position: absolute;
							bottom: 0;
							right: 0;
							width: $insetW;
							height: $insetH;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						a {
							font-size: 1.4rem;
							font-weight: 500;
							display: flex;
							align-items: center;
							justify-content: center;

							@media (max-width: $mobile) {
								justify-content: flex-start;
							}

							img {
								width: 70px;
								height: auto;
								transition: transform $animationDuration;
							}

							&:hover {
								img {
									transform: scale(1.05);
								}
							}

							&.underline-link {
								margin-top: $padding--xs;
								width: auto;
								float: left;
							}
						}

						&.not-video a {
							@include underlineLink;
							margin-top: $padding--xs;
							width: auto;
							float: left;

							@media (max-width: $mobile) {
								margin-left: $padding--xs;
							}
						}

						&.video a {
							text-decoration: none;
							color: inherit;
						}
					}
				}

				img {
					width: 100%;
					height: auto;
					position: relative;
					@media (max-width: $mobile) {
						display: none;
					}
				}
			}

			&:nth-of-type(2) {
				width: calc(100% - 475px);
				padding-left: $padding--lg;

				@media (max-width: $mobile) {
					width: 100%;
					padding-left: 0;
					margin-top: 4.5rem;
				}

				.quote img {
					margin: 0;
				}

				.swiper-slide {
					padding-bottom: $padding--xs;
				}

				p {
					text-align: left;
				}
			}
		}
	}

	@media (max-width: $mobile) {
		.container--sm {
			text-align: left;
		}
		.quote img {
			margin-left: 0;
		}
	}

	.swiper-section.with-two-col {
		@media (max-width: $mobile) {
			display: flex;
			flex-direction: column-reverse;

			.swiper-btm-bar {
				margin-bottom: $padding--sm;
			    border-top: none;
			    border-bottom: 1px solid color(black);
			    padding-top: 0;
			    padding-bottom: $padding--xs;
			}
		}

		.swiper-wrapper {
			display: flex;
			align-items: center;
		}

		.swiper-slide {
			height: 100%;
			padding-bottom: $padding--sm;

			@media (max-width: $mobile) {
				padding-bottom: 0;
			}

			.column-layout {
				.col {
					&:nth-of-type(1) {
						padding: $padding--md 0 0 $padding--md;

						@media (max-width: $mobile) {
							padding: 0;
						}
					}

					&:nth-of-type(2) {
						display: flex;
						flex-direction: column;
						justify-content: center;
					}
				}
			}
		}
	}
}

.cb22-supporter-slider {
	@include paddingTopBottom;
	overflow: hidden;
	.hidden-xs {
		display: block;
	}
	.visible-xs {
		display: none;
	}

	.section-title {
		max-width: 100%;
	}

	.col {
		&:nth-of-type(1) {
			width: 440px;
			padding-right: $padding--md;

			@media (max-width: $mobile) {
				width: 100%;
				padding-right: 0;
			}

			p.p3 {
				margin: $padding--sm 0;
				color: color(accent, darkGray);
			}
		}

		&:nth-of-type(2) {
			width: calc(100% - 440px);

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	.swiper-col {
		overflow: hidden;

		.swiper-section {
			width: 50%;

			@media (max-width: $mobile) {
				width: 100%;
			}

			.swiper-cont {
				overflow: inherit;
			}

			.swiper-pagination {
				bottom: -25px;
			}
		}
	}

  .swiper-cont {
    display: flex;
    flex-wrap: wrap;
		padding-top: 60px;
  }

  .swiper-sidebar {
    flex-basis: 30%;
    padding-right: 40px;
    z-index: 2;
		display: flex;
		.small {
			color: color(accent, darkGray);
			margin-top: 20px;
			font-size: 1.4rem;
		}
  }

	.swiper-sidebar-inner {
		flex-basis: 100%;
		padding-right: 40px;
		background-color: color(white);
	}

	ul.swiper-nav {
		li {
			@include h5-font-styles;
			@include paddingTopBottomXs;
			width: 100%;
			border-bottom: $mainRule;
			text-align: left;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			transition: color $animationDuration;

			&:after {
				content: "";
				display: block;
				border-right: 2px solid color(black);
				border-bottom: 2px solid color(black);
				width: 10px;
				height: 10px;
				transform: rotate(-45deg);
			}

			&.active {
				text-decoration: none;
				color: color(accent); //DESIGNS: GRAD3

				&:after {
					opacity: 1;
				}
			}
		}
	}

  .swiper-section {
    padding-left: 1.75rem;
    width: 100vw;
		.swiper-nav {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
  }

	.header {
		background: color(accent, arctic);
    height: 156px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: $padding--sm $padding--sm $padding--sm 130px;
		margin: -1px;
    position: relative;
	}

	.amount {
		position: absolute;
		top: -30px;
		left: -30px;
    height: 140px;
    width: 140px;
    background: color(accent);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: color(white);
    font-size: 27px;
    font-family: $headlineFont;
    border-radius: 50%;
	}

			// .price {
			// 	font-size: 27px;
			// 	display: flex;
			// 	align-items: center;
			// 	justify-content: center;
				
			// 	border-radius: 50%;
			// 	position: absolute;
			// }
	
  .swiper-wrapper {
    flex-basis: 70%;
    max-width: 70%;
    overflow: visible;
    display: flex;
		padding-top: 30px;
  }

	.swiper-slide {
		padding: 0;
		height: unset;
		border: $mainRule;

		&.placeholder-card {
			border: none;
		}
		.inner {
			padding: 40px;
			padding: $padding--md;
		}
	}

  .swiper-pagination {
    flex-basis: 100%;
		margin-top: 40px;
  }
	.swiper-section .swiper-pagination .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		background-color: color(accent, lightGray);
		border: none;
		margin: 0 8px;
	}
	.swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
		border: none;
		background-color: color(accent, lilac);
	}
}
@media (max-width: $xLarge) {
	.cb22-supporter-slider {
		.header {
			padding: 25px 25px 25px 100px;
			.t-midnight {
				font-size: 2.0rem;
			}
		}
		.amount {
			height: 120px;
			width: 120px;
		}
	}
}

@media (max-width: $mobile) {
	.cb22-supporter-slider {
		.hidden-xs {
			display: none;
		}
		.visible-xs {
			display: block;
		}
		.swiper-sidebar {
			flex-basis: 100%;
		}
		.accordion-section {
			width: 100%;
		}
		.accordion-item {
			.accordion-header {
				border-top: none;
				.expand-toggle {
					background: none;
					height: auto;
					&:after {
						opacity: 1;
						background: none;
						content: "";
						display: block;
						border-right: 2px solid #18181e;
						border-bottom: 2px solid #18181e;
						width: 10px;
						height: 10px;
						transform: rotate(-45deg);
					}
				}
			}
		}
		.accordion-item.open {
			.expand-toggle {
				&:after {
					transform: rotate(45deg);
				}
			}
		}
		ul.swiper-nav li:after {
			display: none;
		}
	}
}
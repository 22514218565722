.cb14-secondary-banner {
	padding-top: $padding--xl;
	background-image: $grad2;

	@media (max-width: $mobile) {
		padding-top: $padding--lg;
	}

	.column-layout {
		.col {
			&:nth-child(1) {
				padding-bottom: $padding--sm;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				@media (max-width: $mobile) {
					padding-bottom: $padding--lg;
				}
			}
		}
	}

	.img-crop {
		height: 0;
		padding-bottom: calc((4 / 5) * 100%);

		video {
			position: relative;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: 9;
			overflow: hidden;
			object-fit: cover;
		}
	}

	.breadcrumbs {
		@media (max-width: $mobile) {
			display: none;
		}
	}

	&.mobile-hide-media .img-crop {
		@media (max-width: $mobile) {
			display: none;
		}
	}
}

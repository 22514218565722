.cb4-related-posts,
.cb13-3-column-tabs {
	@include paddingTopBottom;
	background-image: $grad3;

	h2 {
		color: color(white);
		text-align: center;
	}

	.column-layout {
		margin: 0 0 $padding--lg 0;
		align-items: stretch;

		@media (max-width: $mobile) {
			margin: 0 0 $padding--sm 0;
		}

		.col {
			background: color(white);
		}
	}
}

.event-card,
.tab-card {
	.img-crop {
		padding-bottom: calc((345 / 440) * 100%);
	}

	.btm {
		background: color(white);
		padding: $padding--md;

		p.eyebrow {
			color: color(accent);
		}

		a {
			font-size: 1.4rem;
		}
	}
}

/****************************************

TAB SECTION
Basic show/hide content area with navigation

include js: 
tabSections()

basic structure: 
<div class="tab-section" data-tab-section>
    <div class="tab-nav">
        <div class="tab-link active" data-tab-target="0">Tab 0</div>
        <div class="tab-link" data-tab-target="1">Tab 1</div>
    </div>
    <div class="tab-cont">
        <div class="tab-item active" data-tab="0">Tab 0 Content</div>
        <div class="tab-item" data-tab="1">Tab 1 Content</div>
    </div>
</div>

optional mobile:
add "mobile-active" to tab deired to be open on mobile 

****************************************/

.tab-section {
	.tab-nav {
		@include flexCenter;
		@include internalNav;
		position: relative;

		.tab-link {
			@include internalNavLink;
			padding: 10px;
			cursor: pointer;

			&.active {
				text-decoration: underline;
			}
		}
	}

	.tab-cont {
		position: relative;
		width: 100%;

		.tab-item {
			@include hideLayer;
			visibility: hidden;
			transition: opacity $animationDuration, visibility $animationDuration;

			&.active {
				@include showLayer;
				visibility: visible;
			}

			@media (max-width: bp(sm)) {
				&.mobile-active {
					@include showLayer;
				}
			}
		}
	}

	&.mobile-accordion {
		.tab-nav {
			@media (max-width: $mobile) {
				display: none;
			}
		}

		.tab-item {
			@media (max-width: $mobile) {
				position: relative;
				opacity: 1;
				visibility: visible;
				left: auto;
				pointer-events: auto;
				z-index: 1;
				padding: 0;
			}

			.accordion-header {
				display: none;

				@media (max-width: $mobile) {
					display: flex;
				}
			}

			.accordion-details {
				max-height: 5000px;

				@media (max-width: $mobile) {
					max-height: 0px;
				}

				p {
					max-width: 100% !important;
				}
			}
		}
	}
}

.cb5-drawers {
	padding-top: 14rem;
	padding-bottom: 14rem;
	background-image: $grad5;

	h2 {
		margin-bottom: $padding--md;
	}

  &.light-blue {
    padding-top: 14rem;
  }

  &.offset-jump-links {
    padding-top: calc(14rem + 60px);
  }
}

.cb5-drawers + .cb5-drawers {
	padding-top: 0;
}

.accordion-layout,
.tab-section.mobile-accordion {
	.accordion-item,
	.tab-item {
		background: color(white);
		border-top: $largeWeight solid color(accent, lilac);
		padding: $padding--md;

		@media (max-width: $mobile) {
			padding: $padding--xs;
		}

		.accordion-header {
			border-bottom: none;
			transition: padding $animationDuration;
		}

		.accordion-details {
			@include p-font-styles;
			margin: 0;
			padding: 0;

			p,
			ul,
			ol {
				max-width: $textWrap--lg;
			}

			table tr {
				@media (max-width: $mobile) {
					display: flex;
					flex-direction: column;
					padding: 0;
					border-right: none !important;
				}

				th {
					border-right: none !important;
					@media (max-width: $mobile) {
						padding: 0;
					}
				}
			}
		}

		&.open {
			.accordion-details {
				padding: $padding--xs 0;

				@media (max-width: $mobile) {
					padding: $padding--xs 0 0;
				}
			}
		}
	}
}

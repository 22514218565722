.cb3-tertiary-banner {
	@include paddingTopBottom;
	background-image: $grad2;
	padding-bottom: $padding--md;

	@media (max-width: $mobile) {
		padding-bottom: $padding--sm;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul li {
		color: color(white);
	}

	h1 {
		margin-bottom: 2.8rem;
	}

	.inner {
		max-width: 1150px;
		margin-bottom: $padding--lg;

		&.inner-events {
			max-width: 1150px;

			.links {
				margin-top: $padding--md;
			}
		}
	}
	.links.btns a:first-child {
		color: color(black);
		background-color: color(accent, peach);

		&::after {
			background: radial-gradient(circle closest-side, color(accent, smoke), transparent);
		}
	}
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.85);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999999;
	opacity: 0;
	pointer-events: none;
	transition: all 0.5s;

	&.active {
		opacity: 1;
		pointer-events: auto;
	}

	.modalContent {
		width: calc(100vw - #{$paddingMain} * 2);
		height: calc(100vh - #{$paddingMain} * 2);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		background: $grad5;
		z-index: 999;

		&.scrollable {
			display: block;
			padding: $padding--lg 0;
			overflow-y: auto;
		}

		.title1 {
			&.element-spacing {
				@include elementSpacing;
			}
		}

		.close {
			position: absolute;
			top: 30px;
			right: 20px;
			font-size: 48px;
			line-height: 0;
			cursor: pointer;
			color: color(accent, reflexBlue);
			opacity: 1;
			font-weight: normal;
		}
	}
}

@media (max-width: $mobile) {
	.modal {
		.modalContent {
			padding: 35px;
			.forgot-link {
				top: 15px;
			}
		}
	}
}

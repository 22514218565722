.cb6-wysiwyg.standalone {
	@include paddingTopBottom;
}

.wysiwyg {
	p.title1,
	p.title2,
	p.title3,
	p.title4,
	p.title5,
	p.title6,
	img {
		margin-bottom: 0.5rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1.5rem;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p.title1,
	p.title2,
	p.title3,
	p.title4,
	p.title5,
	p.title6,
	img {
		&:not(:first-child) {
			margin-top: $padding--md;
		}
	}

	img {
		margin-bottom: $padding--xs;
	}

	ul {
		@include bullets;
	}
}

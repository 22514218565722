.attachment {
	display: flex;
	gap: 2rem;
	padding: 1rem 0;

	@media (max-width: $mobile) {
		flex-direction: column-reverse;
	}

	p.name {
		font-weight: 500;
	}

	p.author {
		font-size: 1.3rem;
		color: color(accent, darkGrey);
	}
}
.cb20-footer-cta {
	background: color(accent, red);
	padding-bottom: $padding--md;

	@media (max-width: $mobile) {
		background: none;
		padding: $padding--lg 0;
	}

	.bg-wrapper {
		margin: $padding--lg $padding--md 0;

		@media (max-width: $mobile) {
			margin: 0 $padding--xs;
		}
	}

	.white-text {
		.links a:not(:first-child):hover {
			color: color(white);
		}

		@media (max-width: $mobile) {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			a,
			.links a:not(:first-child):hover,
			ul li {
				color: color(black);
			}
		}
	}

	.mobile-img {
		display: none;

		@media (max-width: $mobile) {
			display: block;
			position: relative;
			padding: 0 0 $padding--xs 0;
			margin: 0 $padding--xs;

			&:after {
				content: "";
				background: color(accent, red);
				width: 100%;
				height: calc(100% - (2 * $padding--xs));
				bottom: 0;
				left: 0;
				z-index: 1;
				position: absolute;
				display: block;
			}

			.img-crop {
				padding-bottom: calc((222 / 312 * 100%));
				z-index: 9;
			}
		}
	}

	h2 {
		@media (max-width: $mobile) {
			text-align: center;
		}

		@media (min-width: $mobile) {
			margin-bottom: 0;
			max-width: 600px;
			margin-left: auto;
		}
	}

	.inner {
		min-height: 600px;
		width: 100%;
		@include paddingAllSides;

		margin-top: calc(-1 * $padding--lg/2);

		@media (max-width: $mobile) {
			background-image: none !important; // important tag required for style override
			padding: 0;
			min-height: auto;
			margin-top: $padding--sm;

			&:before {
				display: none;
			}
		}
	}

	.column-layout {
		width: 100%;
		max-width: 1100px;
		margin: auto;

		.col {
			&:nth-of-type(1) {
				text-align: right;
			}
		}
	}

	.links.single-btn {
		@media (max-width: $mobile) {
			a:first-child {
				background-color: color(accent);
				color: color(white);

				&::after {
					background: radial-gradient(circle closest-side, color(accent, peach), transparent);
				}
			}

			a:not(:first-child) {
				&::before,
				&::after {
					background-color: color(accent, reflexBlue);
				}
			}
		}
	}
}

@media (max-width: $mobile) {
	.cb31-stats + .cb20-footer-cta {
		margin-top: -13rem;
	}
}

.cb32-bio-card {
	@include paddingTopBottom;

	@media (max-width: $mobile) {
		padding-top: 0;
	}

	.bio {
		padding-top: $padding--md;
		background-image: $grad5;
		margin-bottom: calc(1.5 * $padding--lg);

		@media (max-width: $mobile) {
			background-image: none;
			margin-bottom: $padding--md;
		}

		.container--sm {
			@media (max-width: $mobile) {
				width: 100%;
				padding: 0;
			}
		}

		.headshot {
			position: relative;
			margin-bottom: calc(-1 * $padding--lg);

			@media (max-width: $mobile) {
				background-image: $grad5;
				width: 100vw;
				// margin-left: calc(-1 * $padding--xs);
				padding: $padding--xs;
				margin-bottom: calc($padding--md * 2);
			}

			.inner {
				position: relative;
				@include gradUnderline;

				@media (max-width: $mobile) {
					margin-bottom: calc(-1.5 * $padding--md);
				}

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.column-layout {
			align-items: center;
		}

		p {
			line-height: calc(22 / 16);
                        &.title3 > span.field {
                            font-size:inherit;
                        }
		}
	}

	.social {
		display: flex;
		align-items: flex-end;
		margin-bottom: $padding--lg;

		@media (max-width: $mobile) {
			margin-bottom: 0;
		}

		img {
			width: 20px;
			height: auto;
		}

		li:not(:last-child) {
			margin-right: 15px;
		}
	}

	.back {
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		margin-bottom: $padding--xs;

		img {
			width: 20px !important;
			height: auto;
			margin-right: $gap;
		}

		&.mobile {
			@media (min-width: $mobile) {
				display: none;
			}
		}

		&.desktop {
			@media (max-width: $mobile) {
				display: none;
			}
		}
	}

	.tab-nav {
		border-bottom: 1px solid color(black);

		.tab-link {
			@include p-font-styles;
			margin: 0;
			text-decoration: none !important;
			border-bottom: 5px solid transparent;
			transition: border $animationDuration;

			&.active {
				border-color: color(accent);
			}
		}
	}

	.tab-item, .accordion-item {
		border-top: 1px solid color(accent, charcoal) !important;

		&:first-of-type {
			@media (max-width: $mobile) {
				border-top: 5px solid color(accent) !important;
			}
		}

		.container--sm {
			padding: 0;
		}
	}

	.tab-section {
		@media (max-width: $mobile) {
			display: none;
		}

		.tab-item {
			@include paddingTopBottomMd;
		}
		
	}

	.accordion-section {
		@media (min-width: calc($mobile + 1px)) {
			display: none;
		}

		&.open .accordion-details {
			padding: $padding--xs 0 0;
		}

		.accordion-header p {
			font-weight: 500;
		}
	}
        .view-in-the-news{
            .article{
                a:not(.btn) { text-decoration: none;}
                margin-bottom:1em;
                .byline {
                    margin:.25em 0 0 0;
                }
                span.eyebrow {
                    color: color(accent);
                    text-transform:uppercase;
                }

                span.date {
                    font-size: 1.3rem;
                    color: color(accent, darkGrey);
                }
            }
        }
}

.ab6-browse-programs{
  @include paddingTopBottom;
  background: #f5f5f5;

  .top {
    padding: 20px 0;
    margin: 0 0 4rem;
    border-bottom: 1px solid black;
     @media (max-width: $mobile) {
          padding-bottom:0;
    }
    .mobile-filters {
      .views-exposed-form {
        .form-item, .js-form-item {
            &.form-type-checkbox{
                margin-top:1rem;
            }
          float: none;
          width: 100%;

          .control-label {
            @include h5-font-styles();
          }
  
/*          .select-wrapper {
            width: 100%;*/

            select {
              width: 100%;
              padding: 10px 20px;
            }
          /*}*/
        }

        .form-actions {
          margin: 10px 0;

/*          .button {
            background-color: color(accent, peach);
            color: black;
          }*/
        }
      }
       .state-cfp{
            margin-top:1rem;
            .form-item{
              padding: 1rem 0;
            }
            
       }
       .designation-list{
           legend{
               margin-bottom:.5rem;
           }   
       }
    }

    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
    

      #hide-filters {

        @media (max-width: $mobile) {
          display: none;
        }

        &:hover {
          cursor: pointer;
        }

        &.hide {
          display: none;
        }
      }
    }
  }

  .browse-programs {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    
    &.large {
      .col {
        &:first-child {
          flex-basis: 30%;
        }
        &:nth-child(2) {
          flex-basis: 70%;
        }
      }
    }

    &.small {
      padding: 0;
    }

    .col {
      &.filters {
        &.hide {
          display: none;
        }
      }
      &.program-grid {
        &.full {
          flex-basis: 100%;
        }
      }
      .side-filters {
        @media (max-width: $mobile) {
          display: none;
        }

        .experience {
/*          .form--inline {
            fieldset {
              padding-bottom: 2rem; 
              border-bottom: 1px solid black;
            }
          }*/
        }
/*        .interest {
          border-bottom: 1px solid black; 
          width: 75%;
        }*/
        .sort-by-program{
            margin-bottom: 1rem;
        }
        .designation-list{
          margin-bottom: 1.5rem;
          [data-drupal-selector="edit-field-course-designation-target-id"]{
              height:200px;
              width:100%;
              overflow-x:hidden;
              overflow-y: scroll;
              border: 1px solid rgba(0,0,0,0.5);
              padding: 0 .5rem;
              legend{
                  display:none;
              }
              .checkbox{
                  padding-top:1rem;
              }
          }
/*          select {
            width:100%;  
            background-image:none;
            height:125px;
          }*/
      } 
        .state-cfp, .program-type, .interest {
          border-bottom: 1px solid black; 
          margin-bottom: 1.5rem;
          width: 75%;

          .form--inline {
            .form-item-field-program-state-target-id {
              padding-bottom: 2rem; 
              width: 75%;
              display: none;
              
              &.show {
                display: block;
              }
              
              select {
                width: 100%;
              }
            }
          }
        }
        .state-cfp{
            .form-item{
                display: block;
                padding: 5px 0; 
               
            }
            .form-item-field-program-state-target-id{
                label{
                    font-weight:bold;
                }
            }
            p {
                padding-bottom:1rem;
            }
            select {
                width: 100%;
              }
        }

        .form--inline {

          fieldset {
            display: block;
            float: none;
            width: 75%;
            
            legend {
              @include h5-font-styles();
              margin: $padding--xs 0;
            }
          }
        }
        
        .form-checkboxes {
          .form-item {
            display: block;
            padding: 5px 0;
          }
          .js-form-item  {
            display: grid;
            grid-template-columns: 1rem auto;
            gap: 1rem;
            padding: 5px 0;
          }
        }
        &.hide {
          display: none;
        }
      }

      .programs {

        &.halves {
          .views-infinite-scroll-content-wrapper {
            width: 100%;
            justify-content: space-between;
            .card {
              width: calc((100%/2) - 1rem);

              .img-banner {
                display: none;
              }
              &:nth-child(even) {
                  .tool-tip{
                      .content {
                          left:unset;
                          right:10px;
                      }
                  }
              }
            } 
            &.clearfix::after{
                content:none;
            }
          }
        }

        .views-infinite-scroll-content-wrapper {
          width: 100%;
          @extend .card-layout;
          

          .card {
            &.topbar-mastersdegreesgraduateprograms  {
              border-top: 5px solid color(accent, peach);
            }
            &.topbar-mastersdegreeprogram  {
              border-top: 5px solid color(accent, peach);
            }
            
            &.topbar-continuingeducationcourses {
              border-top: 5px solid color(accent, sky);
            }
            &.topbar-continuingeducation {
              border-top: 5px solid color(accent, sky);
            }
            &.topbar-ce {
              border-top: 5px solid color(accent, sky);
            }
            
            &.topbar-professionaldesignationscertifications {
              border-top: 5px solid color(accent, lilac);  
            }
            &.topbar-professionaldesignationscertificates {
              border-top: 5px solid color(accent, lilac);  
            }
            &.topbar-certificateprogram{
                border-top: 5px solid color(accent, peach);  
            }
           
            &.topbar-consumerfinancialeducation{
               border-top: 5px solid color(accent, sky);  
            }
            .img-banner {
              height: 150px;
              background-size: cover;
            }
  
            .btm {
              .top {
                margin: 0 2rem;
                border-bottom: 1px solid color(grey, rules);
                p.p4{
                   a{
                        color:inherit;
                   } 
                } 
              }
              .links {
                padding: 2rem;
  
                a {
                  &.btn {
                    padding: 10px 25px;
                  }
                  &:first-child {
                    margin-right: 1rem;
                  }
                }
              }
              .miscLinks{
                  width:100%;
                  text-align:left;
              }
              .relatedProgs{
                  margin: 0 2rem;
                    padding-top: 2rem;
                  .label{
                       display: block;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 1px;
                  }
                  a{
                      text-decoration:none;
                  }
              }
            }
          }
        }
      }
    }
  }
  .control-label.option, .js-form-type-checkbox.js-form-item  {
    display: grid;
    grid-template-columns: 1rem auto;
    gap: 1rem;
    padding: 5px 0;
  }
  
  input[type="checkbox"] {
    appearance: none;

    &:before {
      content: " ";
      display: block;
      width: 15px;
      height: 15px;
      border: 1px solid color(accent, base);
      border-radius: 2px;
      /*position: absolute;*/
      background-color: white;
      cursor: pointer;
      margin-top:.2rem;
    }

    &:checked:before {
      background-image: url("data:image/svg+xml,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.5' y='0.5' width='17' height='17' rx='1.5' fill='%234200ED'/%3e%3cmask id='mask0_1503_2219' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='2' y='4' width='14' height='10'%3e%3cpath d='M6.59422 11.9063L3.99172 9.30379C3.8516 9.16335 3.66136 9.08442 3.46297 9.08442C3.26458 9.08442 3.07434 9.16335 2.93422 9.30379C2.64172 9.59629 2.64172 10.0688 2.93422 10.3613L6.06922 13.4963C6.36172 13.7888 6.83422 13.7888 7.12672 13.4963L15.0617 5.56129C15.3542 5.26879 15.3542 4.79629 15.0617 4.50379C14.9216 4.36335 14.7314 4.28442 14.533 4.28442C14.3346 4.28442 14.1443 4.36335 14.0042 4.50379L6.59422 11.9063Z' fill='black'/%3e%3c/mask%3e%3cg mask='url(%23mask0_1503_2219)'%3e%3crect width='18' height='18' fill='white'/%3e%3c/g%3e%3c/svg%3e");
      background-position: -1px -1px;
      background-repeat: no-repeat;
      background-size: 15px 15px;
    }
  }
  
    .layout-container.show-state {
      .form-item-field-program-state-target-id {
        display: block !important;
      }
    }
    li[class^="ce-state-"] {
      display:none;
    }
    li[class^="ce-state-"].active {
      display:block;
    }
    li.ce-state-default{
        display:none;
    }
    // Test display for CE
    /*li.ce-state-al{
        display:block;
    }*/
    ul.ce-list{
        margin-top:.5rem;
    }
    .ce-filter-state-desc, .ce-filter-cfp-desc{
       display:none;
    }
    .ce-filter-state-desc, .ce-filter-cfp-desc{
        &.active{
            font-style: italic;  
            width:200px;
            display:block;
        }
    }
    /*[data-drupal-selector="edit-field-course-ce-state-target-id"]{
        :disabled{
            cursor:not-allowed;
        }
    }*/
    [data-drupal-selector="edit-field-course-ce-state-target-id"]:disabled {
        cursor: not-allowed;
    }
    /*[data-drupal-selector="edit-field-cfp-ce-available-value"]{
        :disabled{
            cursor:not-allowed;
        }
    }*/
    [data-drupal-selector="edit-field-cfp-ce-available-value"]:disabled {
        cursor: not-allowed;
    }
    ul[data-drupal-views-infinite-scroll-pager]{
        text-align:center;
        li{
            display:inline-block;
        }
    }
    [data-drupal-selector="edit-actions"]{
        text-align:center;
        button{
            width: 45%;
            &:not(.js-hide){
                display:inline-block; 
            }
            @media (max-width: $mobile) {
                width: 100%;
            }
        }

    }
    button[data-drupal-selector^="edit-reset-programs"], button[data-drupal-selector^="edit-reset-programs-mobile"], input[data-drupal-selector^="edit-reset-programs"], input[data-drupal-selector^="edit-reset-programs-mobile"]{
            all: initial;
            * {
              all: unset;
            }
            display:inline;
            cursor:pointer;
            font-family: inherit;
            font-size: 100%;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;
            margin: 0;
            padding: 0;
            //Now style like a link!
            color: color(accent);
            text-decoration: underline;
            margin-bottom: 1rem;
        
        &:not(.js-hide){
            display:block; 
        }
        @media (max-width: $mobile) {
            width:100%;
            text-align:center;
            background-color:transparent;
            margin-top:1rem;
        }
    }
    .tool-tip {
        display: flex;
        align-items: center;

        @media (max-width: $mobile) {
                /*justify-content: center;*/
                a{
                    font-size:1.3rem;
                }
        }

        .icon {
            height:15px;
            width:15px;
                min-width: 15px;
                min-height: 15px;
                font-size: 1rem;
        }

        .content {
                top: 30px;
                left: 0;
                z-index:30;
                @media (max-width: $mobile) {
                        /*width: 100%;*/
                        left: 0;
                }

                p:not(.wysiwyg-title) {
                        @extend .p4;
                }
        }
    }
    .tool-tip-link{
        padding-top:1rem;
    }
    hr {
        margin:3rem 0;
    }
    .designation-list{
        @media (max-width: $mobile) {
            padding-top:2rem;
            select {
            width:100%;  
            background-image:none;
            height:125px;
          }
        }
    }
    p.ca-only{
        padding: 0 1.5rem 1.5rem 1.5rem;
        display:none;
    }
}

.drop-box {
	background: color(white);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.padding {
	&--lg {
		padding: $padding--lg;

		@media (max-width: $mobile) {
			padding: $padding--sm;
		}
	}

	&--sm {
		padding: $padding--sm;
	}
}

.m-btm-0 {
	margin-bottom: 0;
}

.card {
	h1,
	h2,
	h3,
	h4,
	h5,
	.title1,
	.title2,
	.title3,
	.title4 {
		margin-bottom: 0;
	}
}

.column-layout {
	.card {
		width: 100%;
		@include elementSpacing;
	}
}

html,
body,
#root {
	scroll-behavior: smooth;
}

.breadcrumbs {
	display: flex;
	align-items: center;

	* {
		@include underlineLink;
		@include eyebrow;
		font-weight: 500;
		text-transform: uppercase;
		color: color(white);
		text-decoration: none;
		margin: 0;
		display: flex;
		align-items: center;
	}

	p,
	span {
		pointer-events: none;
		
		&:before,
		&:after {
			display: none;
		}
	}

	span {
		margin: 0 10px;
	}

	a {
		&:before,
		&:after {
			background: color(accent, peach);
		}

		&:hover {
			text-decoration: none;
		}
	}

	&.dark {
		* {
			color: color(accent, midnight);
		}

		a {
			&:before,
			&:after {
				background: color(accent, midnight);
			}
		}
	}
}

.dark { 
    .breadcrumbs {
	* {
		color: color(accent, midnight);
	}

	a {
		&:before,
		&:after {
			background: color(accent, midnight);
		}
	}
       }
}

.section-title {
	text-align: center;
	max-width: $textWrap--lg;
	margin: 0 auto $padding--sm;
}

.section-title-xl {
	text-align: center;
	max-width: $textWrap--xl;
	margin: 0 auto $padding--sm;
}

.section-title-md {
	text-align: center;
	max-width: $textWrap--md;
	margin: 0 auto $padding--sm;
}

.tool-tip {
	position: relative;
	cursor: pointer;

	@media (max-width: $mobile) {
		display: none;
	}

	.icon {
		width: 20px;
		height: 20px;
		background: color(accent);
		color: color(white);
		font-weight: 500;
		font-size: 1.4rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 9;
		position: relative;
	}

	.content {
		position: absolute;
		top: 10px;
		left: 10px;
		overflow: hidden;
		background: color(accent, lightMist); // rgba(207, 207, 255, 0.4);
		width: 300px;
		padding: 0 $padding--sm;
		max-height: 0px;
		transition: max-height 0.5s, padding 0.5s;

		.title5 {
			color: color(accent, midnight);
		}
	}

	&:hover, &:focus {
		.content {
			padding: $padding--sm;
			max-height: 700px;
		}
	}

	&:has(a:focus) { // :has will not work in ff now but should later this year
		.content {
			padding: $padding--sm;
			max-height: 700px;
		}
	}
}

.read-more {
	.remaining-content {
		display: none;
	}

	.underline-link {
		margin-left: 5px;
		.less {
			display: none;
		}
	}

	&.active {
		.remaining-content {
			display: inline;
			//margin-left: 5px;
		}

		.ellipse {
			display: none;
		}

		.underline-link {
			.less {
				display: inline;
			}

			.more {
				display: none;
			}
		}
	}
}

.expired-offer h1 {
	margin: 60px 0 -60px;
}

// For neighboring paragraphs with the
// same background, remove excess padding
.white + .white,
.blue + .blue,
.light-blue + .light-blue,
.charcoal + .charcoal,
.white + .cb6-wysiwyg.standalone {
	padding-top: 0;
}

.cb10-newsletter-signup {
	padding: $padding--md 0 $padding--lg;
	margin-top: $padding--md;
	background: color(accent, charcoal);

	@media (max-width: $mobile) {
		padding: $padding--md 0;
	}

	p.eyebrow--lg {
		color: color(accent, mist);
	}

	.submitted-message {
		margin-bottom: $padding--xs;
	}

	.column-layout {
		.col {
			&:nth-of-type(1) {
				width: calc(100% - 480px);
				padding-right: $padding--md;
				padding-top: $padding--md;

				@media (max-width: $mobile) {
					width: 100%;
					max-width: 480px;
					margin: 0 auto;
					padding-right: 0;
					margin-bottom: $padding--md;
					text-align: center;
				}

				form {
					margin: $padding--sm 0;
				}
			}

			&:nth-of-type(2) {
				width: 480px;

				@media (max-width: $mobile) {
					width: 100%;
					max-width: 480px;
					margin: 0 auto;
				}

				img,
				svg {
					width: 100%;
					height: auto;
					margin-top: calc($padding--lg * -1.5);

					@media (max-width: $mobile) {
						margin-top: 0;
					}
				}
			}
		}
	}

	.hbspt-form {
		ul.inputs-list.multi-container li {
			width: 100%;
		}

		input[type="submit"] {
			margin: 0;
		}
	}
}

.arrow-link-list {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: column;
	max-height: 100px;

	@media (max-width: $mobile) {
		max-height: none;
	}

	li {
		border-right: 1px solid color(gray);
		margin-right: $padding--sm;

		@media (max-width: $mobile) {
			border-right: none;
			padding-bottom: 1rem;
		}

		&:nth-of-type(odd) {
			padding-bottom: 1rem;
		}

		&:last-child,
		&:nth-last-child(2):nth-of-type(odd) {
			border-right: none;
		}

		a {
			font-size: 1.6rem;
			font-weight: 600;
			color: color(white);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-family: $headlineFont;
			width: 100%;
			padding-right: 5px;
			transition: padding $animationDuration;

			&:before {
				content: "";
				width: 15px;
				height: 1.5px;
				background: color(accent, peach);
				display: block;
				margin-left: 10px;
				margin-right: -7px;
				order: 1;
				transition: width $animationDuration;
			}

			&:after {
				content: "";
				background-image: url("../imgs/icons/arrow-shape-peach.svg");
				height: 15px;
				width: 10px;
				order: 1;
				background-size: auto 100%;
			}

			&:hover {
				color: color(accent, peach);
				padding-right: 0;
				transition: padding $animationDuration;

				&:before {
					width: 20px;
				}
			}
		}
	}
}

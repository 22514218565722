.cb23-donor-tiers {
	@include paddingTopBottom;
	background-image: $grad5;

	.donor {
		&:not(:last-child) {
			margin-bottom: $padding--sm;
		}

		+ .accordion-item {
			margin-top: calc(-1 * $padding--sm);
		}
	}

	.btn {
		margin: auto;
	}

  .pdf-link {
    @extend .btn;
  }

  .accordion-section {
  	padding-top: $padding--lg;

  	@media (max-width: $mobile) {
  		padding-top: $padding--md;
  	}
  }

	.cards {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-rows: 1fr;
		grid-column-gap: $padding--sm;
		grid-row-gap: $padding--sm;
		border-bottom: $largeWeight solid color(white);
		padding-bottom: $padding--sm;

		@media (max-width: $mobile) {
			grid-template-columns: repeat(1, 1fr);
			grid-row-gap: $padding--xs;
		}
	}

	.card {
		background: color(white);
		padding: $padding--sm 0 calc($padding--sm + 11px);
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		@include gradUnderline;

		img {
			width: 80%;
			height: auto;

			&.link {
				position: absolute;
				bottom: 21px;
				right: 10px;
				width: 26px;
				height: auto;
				transition: transform $animationDuration;
			}
		}
	}

	a.card {
		&:hover img.link {
			transform: scale(1.05);
		}
	}

	.accordion-item {
		border-bottom: $largeWeight solid color(white);
		padding-bottom: $padding--sm;

		@media (max-width: $mobile) {
			padding-bottom: $padding--xs;
		}

		.accordion-header {
			border-bottom: none;
			padding-top: $padding--sm;

			@media (max-width: $mobile) {
				padding-top: $padding--xs;
			}
		}

		.accordion-details {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.cards {
			border-bottom: none;
			padding: 0;
			margin: 0;
		}
	}
}

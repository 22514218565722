.ab-16-search-resource-feature {
	@include paddingTopBottom;

  @media (max-width: $mobile) {
    padding-top: 0;
  }

  .container {
    @media (max-width: $mobile) {
      display: block;
    }
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 525px;
    height: 100%;

    &.top {
      padding-bottom: 3rem;
      .views-element-container {
        width:48%;
        @media (max-width: $mobile) {
            display: block;
            width: 100%;
            padding-left: 0;
          }
      }
    }
    &.bottom {
        .view-content {
           display: flex;
            align-items: stretch;
            justify-content: space-between;
            @media (max-width: $mobile) {
                display: block;
                width: 100%;
              }
        }
    }

    .form-wrapper {
      background: $grad5;
      @media (max-width: $mobile) {
        width: calc(100% + 2 * $padding--xs);
        margin: 0 calc(-1 * $padding--xs) 6rem;
        padding: 6rem 2rem;
        .form-title {
          text-align: center;
        }
      }
      width: 48%;
      padding: 6rem 6rem;

      .form-title {
        padding-bottom: 3rem;
      }

      #search-resource-form {
        @include fieldStyle;
        display: block;

        label {
          font-weight: 500;
        }

        .text-input-wrapper,
        .category-select-wrapper {
          display: block;
          padding-bottom: 3rem;

          input, select {
            width: 100%;
          }
        }
        .form-actions {
          .btn, #reset-search {
            @media (max-width: $mobile) {
              display: block;
            }
            display: inline-block;
          }
          .btn {
            margin-right: 2rem;
          }
          #reset-search {
            border-bottom: 2px solid color(accent, base);
            @media(max-width: $mobile) {
              margin: 0 auto;
              padding-top: 2rem;
              width: fit-content;
            }
          }
        }
      }
    }
        .quad {
          @media (max-width: $mobile) {
            display: block;
            width: 100%;
            padding-left: 0;
          }

          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          /*width: 50%;*/
          padding-left: 2rem;

          .card {
            flex-basis: 50%;
            margin-bottom: 1rem;

            p {
              margin: 0 0 .7rem;
            }
            @media (min-width: $mobile) {
              &:nth-child(odd) {
                padding-right: 2rem;
              }
              &:nth-child(even) {
                padding-left: 2rem;
              }
            }
          }
        /*}*/
    }
    .single {
      @media (max-width: $mobile) {
        display: block;
        width: 100%;
      }
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0;
      width: 50%;
      
      @media (min-width: $mobile) {
        &.first {
          border-right: 1px solid color(accent, lightGray);
          padding-right: 2rem;
        }
        &.last {
          padding-left: 2rem;
        }
      }
    }
  }
}

html {
	--hero-opacity: 1;
}

.ab8-history-timeline{
	// @include paddingTopBottom;
	color: color(accent, charcoal);

	h3 {
		font-style: italic;
	}

	.center {
		margin: 0 auto;
		text-align: center;
	}

	span.blue-highlight {
		@include articTextHighlight;
	}

	#hero {
		
		@media (max-width: $mobile) {
			margin: 0 !important; // important tag required for style override
		}

		display: flex;
		height: 900px;	

		
		div {		
			@media (max-width: $mobile) {
				padding: 10rem 1rem;
				margin: 0 !important; // important tag required for style override
			}
			position: relative;
			padding: 20rem 13rem 13rem 13rem;
			h2 {
				color: white;
				font-size: 8.3rem;
				line-height: 8.3rem;
				margin: 0 auto;
				width: 80%;

				@media (max-width: $mobile) {
					font-size: 3.6rem;
					line-height: 3.9rem;
					width: 95%;
					padding: 0 2.5%;
				}
			}
			h3 {
				opacity: 0;
				padding-top: 5rem;

				@media (max-width: $mobile) {
					font-size: 2.9rem;
					line-height: 3.4rem;
					width: 95%;
					padding: 4.0rem 1.6rem;
				}
			}
		}

		&::before {
			background-size: cover;
			background-position: center;
			position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      height: 100%;
      opacity: var(--hero-opacity);				
		}
		
	}

	.hero-sub {
		padding: 10rem;

		h2 {
			font-size: 8.3rem;
			line-height: 8.3rem;
			width: 60%;
			margin: 0 auto 4.0rem auto;
		}
	}

	// Marquee implementation
	@keyframes scroll {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(calc(-100% - var(--gap)));
		}
	}

	.marquee {
		--gap: 1rem;
		display: flex;
		overflow: hidden;
		user-select: none;
		gap: var(--gap);
		background: white;

		.marquee__content {
			flex-shrink: 0;
			display: flex;			
			gap: var(--gap);		
			animation: scroll 10s linear infinite;
			font-size: 18rem;
			line-height: 18rem;
			text-transform: uppercase;
			padding-bottom: 16rem;

			@media (max-width: $mobile) {
				font-size: 4.8rem;	
				line-height: 4.8rem;	
				padding: 1rem 0;
			}
			
			&:after {
				content: " ";
				background: url("/themes/custom/tac23-theme/static/assets/imgs/logos/our-story-tac-shield-logomark.png") no-repeat center center;
				background-size: 62%;
				display: inline-block;
				height: 180px;
				width: 180px;
				
				@media (max-width: $mobile) {
					background-size: 80%;
					background-position: bottom;
					width: 4.2rem;
					height: 4.2rem;
				}	
			}
		}
	}
	
	
	// Timeline

	
	
	@keyframes pulse-animation {
		0% {
			box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
		}
		100% {
			box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
		}
	}

	.timeline {
		

		#indicator {
			display: block;

			background: color(accent, charcoal);
			font-size: 1.4rem;
			line-height: 1.4rem;
			border-radius: 100px;
			padding: 15px 24px;
			color: white;
			position: absolute;
			left: 50%;
			width: 8rem;
			margin-left: -3rem;
			text-align: center;
			z-index: 90;

			&.pulse {
				animation: pulse-animation 1s 1;
			}
			

			@media (max-width: $mobile) {		
				display: none; // remove indicator functionality on mobile
			}
		}
		ol {
			background-image: linear-gradient(to bottom, #fff 120px,color(accent, arctic) 120px, color(accent, arctic) 100%);
			background-image: -webkit-linear-gradient(to bottom, #fff 120px,color(accent, arctic) 120px, color(accent, arctic) 100%);
			// background-image: -moz-linear-gradient(to bottom, transparent 120px,color(accent, arctic) 120px, color(accent, arctic) 100%);
			// background-image: -ms-linear-gradient(to bottom, transparent 120px,color(accent, arctic) 120px, color(accent, arctic) 100%);
			
			&.--no-bg {
				background: none;
			}

			margin: 0 auto;
			padding-top: 5rem;
			max-width: 100rem;

			@media (max-width: $mobile) {		
				background: color(white);
				padding-top: 0;
			}
	
			li {
				
				list-style-type: none;
				background: color(accent, charcoal);
				width: 2px;
				position: relative;
				margin: 0 auto; // vertical line centered in screen
				padding-bottom: 5rem;

				@media (max-width: $mobile) {		
					margin: 0; // vertical line to left-side of screen
					font-size: 1.6rem;
					line-height: 2.8rem;
				}
	
				// Timeline "dots"
				&::before {
					content: "";
					position: absolute;
					top: 120px;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					width: 1rem;
					height: 1rem;
					border-radius: 50%;
					background: inherit;
				}			

				// Hides timeline dots
				&.--no-dot-indicator {
					&::before {
						width: 0;
						height: 0;
					}
				}
	
				> div {									
					margin: 0 1rem;
					padding: 1rem;
					@media (min-width: $mobile) {	
						width: 47rem;
					}
					@media (max-width: $mobile) {	
						min-width: 25rem;						
					}
					
				}

				> div.img-border {
					position: relative;

					> img {
						@media (max-width: $mobile) {		
							margin: 0;
							width: 100%;
						}

						position: relative;
						z-index: 1;	
					}

					&::after {
						content: "";
						display: block;
						background: linear-gradient(172deg, rgba(66, 0, 237, 1) 0%, rgba(111, 102, 177, 1) 35%, rgba(111, 102, 177, 1) 100%);
						width: 80%;
						height: 80%;
						top: -1rem;
						right: -1rem;
						position: absolute;
						z-index: 0;
					}
				}
	
				&:nth-child(odd) {				
					> div {
						margin-left: -60rem;

						@media (max-width: $mobile) {		
							margin-left: 1rem;
							margin-top: 0;
						}
					}
					
				}

				&:nth-child(even) {				
					> div {
						margin-left: 16rem;

						@media (max-width: $mobile) {		
							margin-left: 1rem;
							margin-top: 0;
						}
					}
					
				}
	
				time {
					display: block;
					font-family: Cambon-Demi,serif;
					font-size: 8.3rem;
					line-height: 1;
					margin-bottom: 1.6rem;
					color: transparent;
					-webkit-text-stroke: 2px #4200ed;
					text-align: left;
					-webkit-background-clip: text !important; // important tag required for style override
					will-change: background-image;

					@media (max-width: $mobile) {		
						font-size: 4.2rem;
						margin-bottom: 1.6rem;
					}
				}
			}

			&.parallax-imgs {
				width: 100%;

				li {
					&:nth-child(odd) {
						
						.img-container {

							img {
								@media (max-width: $mobile) {		
									margin: 0 auto;
									margin-left: 0;
									width: 75%;
								}

								margin-left: calc(100vw * 0.15);
							}

						}
					}

					&:nth-child(even) {

						.img-container {
							
							img {
								@media (max-width: $mobile) {		
									margin: 0 auto;
									margin-left: 0;
									width: 75%;
								}

								margin-left: calc(100vw * -0.2);
							}

						}

					}

				}
			}
		}

		&.--reverse ol li {
			&:nth-child(even) {				
				> div {
					margin-left: -44rem;

					@media (max-width: $mobile) {		
						margin-left: 1rem;
					}
				}
			}
			&:nth-child(odd) {				
				> div {
					margin-left: 1rem;

					@media (max-width: $mobile) {		
						margin-left: 1rem;
					}
				}
			}
		}

		&.--no-dot-indicator ol {
			background: none;
			
		}
		
	}
}

.cb26-cta-grid {
	@include paddingTopBottom;

	h2 {
		margin-bottom: $padding--md;
	}

	.card {
		border-bottom: $mainRule;
		padding: 0 0 $padding--xs 0;
		position: relative;

		&:after {
			content: "";
			width: 1px;
			background: color(grey, rules);
			height: 100%;
			position: absolute;
			top: -5%;
			right: calc(-0.5 * $padding--sm);
		}

		&:nth-child(3n + 3):after {
			display: none;
		}

		p.eyebrow {
			color: color(accent);
		}

		p.title2,
		p.title3,
		p.title4 {
			margin-bottom: $padding--xs;
		}

		p.title {
			@include h4-font-styles();
			margin-bottom: $padding--xs;
		}

		&.highlight p.title {
			@media (min-width: $mobile) {
				@include h2-font-styles();
			}
			margin-bottom: $padding--xs;
		}

		&.highlight {
			@media (min-width: $mobile) {
				background: color(accent, peach);
				display: flex;
				flex-direction: column;
				align-items: space-between;
				justify-content: space-between;
				padding: $padding--md;
			}

			.inner {
				@media (min-width: $mobile) {
					width: 60%;
				}
			}

			> img {
				width: 125px;
				height: auto;
				align-self: flex-end;

				@media (max-width: $mobile) {
					display: none;
				}
			}
		}
	}

	.cards {
		display: grid;
		grid-gap: #{$padding--sm};

		&.v1 {
			grid-template-columns: repeat(4, 1fr);

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}

			.card {
				&:nth-of-type(1) {
					grid-column: 1/3;
					grid-row: 1/5;

					&:after {
						display: none;
					}

					@media (max-width: $mobile) {
						grid-column: inherit;
						grid-row: inherit;
					}
				}

				&:nth-of-type(2) {
					grid-column: 3/5;

					@media (max-width: $mobile) {
						grid-column: inherit;
						grid-row: inherit;
					}

					&:after {
						display: none;
					}

					p.title {
						@media (min-width: $mobile) {
							@include h2-font-styles();
						}
						margin-bottom: $padding--xs;
					}
				}

				&:nth-of-type(3) {
					&:after {
						display: block;
					}
				}

				&:nth-of-type(4) {
					&:after {
						display: none;
					}
				}

				&:nth-child(4n + 1):nth-last-child(-n + 4),
				&:nth-child(4n + 1):nth-last-child(-n + 4) ~ .card {
					@media (min-width: $mobile) {
						border-bottom: none;
					}
				}
			}
		}

		&.v2 {
			grid-template-columns: repeat(2, 1fr);

			@media (max-width: $mobile) {
				grid-template-columns: 1fr;
			}

			.card {
				&:after {
					display: none;
				}

				&:nth-of-type(1) {
					//grid-column: 1/3;
					grid-row: 1/3;

					@media (max-width: $mobile) {
						grid-column: inherit;
						grid-row: inherit;
					}
				}

				&:nth-child(2n + 1):nth-last-child(-n + 2),
				&:nth-child(2n + 1):nth-last-child(-n + 2) ~ .card {
					@media (min-width: $mobile) {
						border-bottom: none;
					}
				}
			}
		}

		&.v3 {
			@media (max-width: $mobile) {
				display: none;
			}

			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 1fr;

			&:nth-child(3n + 1):nth-last-child(-n + 3),
			&:nth-child(3n + 1):nth-last-child(-n + 3) ~ .card {
				border-bottom: none;
			}

			:nth-last-child(-n + 3) {
				border-bottom: none;
			}
		}
	}

	.mobile.v3 {
		@media (min-width: $mobile) {
			display: none;
		}
	}
}

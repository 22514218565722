/****************************************

GLOBAL

****************************************/

/****************************************
REBASE
****************************************/

* {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	font-size: 10px;
	color: color(black);
	font-family: $bodyFont;
	background-color: #fff;
	@media (max-width: $mobile) {
		width: 100%;
		overflow-x: hidden;
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}
}

p,
h1,
h2,
h3,
h4 {
	margin: 0;
	padding: 0;
	color: color(black);
}

main {
	//min-height: calc(100vh - 200px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

/****************************************
Responsive switches
****************************************/
.desktop {
	display: block;

	@media (max-width: $mobile) {
		display: none;
	}
}

.mobile {
	display: none;

	@media (max-width: $mobile) {
		display: block;
	}
}

.desktop-inline {
	display: inline;

	@media (max-width: $mobile) {
		display: none;
	}
}

.mobile-inline {
	display: none;

	@media (max-width: $mobile) {
		display: inline;
	}
}

.desktop-flex {
	display: flex;

	@media (max-width: $mobile) {
		display: none !important; // important tag required for style override
	}
}

.mobile-flex {
	display: flex;

	@media (min-width: $mobile) {
		display: none !important; // important tag required for style override
	}
}

/****************************************
Structural components
****************************************/
section,
.full {
	width: 100%;
	position: relative;
}

/****************************************
Responsive video frame
****************************************/
.responsive-video {
	width: 100%;

	.video-size-frame {
		height: 0;
		padding-bottom: 56.3%;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

/****************************************
Image crop
****************************************/

.img-crop {
	width: 100%;
	padding-bottom: 66.7%;
	position: relative;

	&.two-three {
		padding-bottom: 66.7%;
	}

	&.sixteen-nine {
		padding-bottom: 56.3%;
	}

	&.square {
		padding-bottom: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

/****************************************
Image enlarge on hover
****************************************/

.img-enlarge {
	overflow: hidden;
	width: 100%;
	padding-bottom: 66.7%;
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: none;
		object-fit: cover;
		transition: all 1s;

		&:hover {
			width: 115%;
			height: 115%;
			left: -7.5%;
			top: -7.5%;
		}
	}
}

/****************************************
quick show/hide layers
****************************************/
@mixin showLayer {
	position: relative;
	top: auto;
	width: 100%;
	pointer-events: auto;
	opacity: 1;
	z-index: 1;
}

@mixin hideLayer {
	position: absolute;
	width: 100%;
	opacity: 0;
	left: 0;
	pointer-events: none;
	z-index: -5;
}

/****************************************
flex layouts
****************************************/
@mixin flexCenter {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: $mobile) {
		flex-direction: column;
	}
}

.flex {
	align-items: center;
	justify-content: space-between;
}

.flex-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.flex-center {
	@include flexCenter;

	.col {
		width: 100%;
		flex: 1 1 auto;
		flex-direction: column;
	}
}

/****************************************
ui basics
****************************************/
@mixin internalNav {
	@include flexCenter;
}

@mixin internalNavLink {
	width: 100%;
	flex: 1 1 auto;
	text-align: center;
}

/****************************************
UTILITY CLASSES
****************************************/
.relative {
	position: relative;
}

/****************************************
BACKGROUNDS
****************************************/
@mixin bgFill() {
	background-size: cover;
	background-position: center center;
}

.bg-fill {
	@include bgFill();
}

.bg-reveal {
	@include bgFill();
	background-attachment: fixed;
}

.cb15-resource-post {
	@include paddingTopBottom;
	@media (max-width: 1024px) {
		padding: 7rem 0;
	}

	.column-layout.resource-sidebar {
            
		.side, .mobile {
			.author {
				display: flex;
				align-items: center;

				&:not(:last-of-type) {
					margin-bottom: $padding--xs;
				}

				.headshot {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					overflow: hidden;
				}

				.inner {
					padding-left: $padding--xs;

					p {
						margin: 0;
						line-height: 1.3;

						strong {
							font-weight: 500;
						}
					}
				}
			}
			.post-item {
				&:not(:last-child) {
					margin-bottom: $padding--sm;
				}

				p {
					margin: 0;

					&.author {
						color: color(accent, darkGray);
						font-size: 1.3rem;
						margin: $gap 0;
					}
				}
			}
		}
		.side {
			form,
			.hbspt-form {
				form {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
				}

				.form-columns-2,
				.form-columns-1 {
					flex-basis: 100%;
					flex-grow: 1;
				}

				input,
				select {
					border: 1px solid color(black);
					padding: 5px 10px;
					font-size: 1.4rem;
					width: 100%;
				}

				.hs_error_rollup {
					width: 100%;
				}

				// Adds custom margin specifically for default newsletter form
				.hs-form[data-form-id="bf9e92c0-0126-466c-9fb3-00f84c0abb7f"] {
					.hs_submit {
						margin-top: 12px;
					}
				}

				.form-columns-2 {
					flex-wrap: wrap;
					> * {
						@media (max-width: 768px) {
							margin-bottom: 1.75rem;
						}
					}
				}

				.hs-form-field,
				.hs-dependent-field {
					flex-basis: 100%;
				}

				.hs-form-booleancheckbox input {
					width: unset;
					margin: 0 10px 0 0;
					position: relative;
					top: 2px;
				}

				.hs-form-checkbox-display {
					align-items: flex-start;
					input {
						flex-basis: 20px;
						border: none;
						padding: 0;
						position: relative;
						top: 0;
					}
				}
				.inputs-list.multi-container li.hs-form-checkbox {
					width: 100%;
					padding-bottom: 5px;
					input {
						@media (max-width: 1024px) {
							margin-top: 0;
						}
					}
				}

				input[type="submit"] {
					@include btnStyle;
					padding: 11px 15px;
					margin: 5px 0;
				}
				.hs_submit input[type=submit]:hover {
					@media (max-width: $mobile) {
						background: color(accent, base);
					}
				}
			}

			.item {
				@include paddingTopBottomSm;

				&:first-of-type {
					padding-top: 0;
				}

				&:not(:last-child) {
					border-bottom: $mainRule;
					border-color: color(accent, darkGray);
				}
			}

			.desktop {
				.item:first-of-type {
					padding-top: $padding--sm;
				}
			}

			.links {
				flex-direction: column;

				.btn {
					width: 100%;

					&:not(:last-of-type) {
						margin-bottom: $padding--xs;
					}
				}
			}
		}

		.col {
			&:nth-child(1) {
				width: 320px;

				@media (max-width: $mobile) {
					width: 100%;
					margin-bottom: $padding--md;
				}
			}

			&:nth-child(2) {
				width: calc(100% - 320px);
				padding-left: $padding--lg;

				@media (max-width: $mobile) {
					width: 100%;
					padding-left: 0;
				}

				.cb6-wysiwyg.standalone {
					padding: 0;
				}

				.container--sm,
				.container {
					padding: 0;// !important;
				}

				p.eyebrow {
					color: color(accent);
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					span {
						display: flex;
						align-items: center;

						a {
							text-decoration: none;
						}

						&:not(:last-of-type) {
							&:after {
								content: "";
								width: 2px;
								height: 10px;
								background: color(accent);
								display: block;
								margin: 0 $gap;
							}
						}
					}
				}

				.wysiwyg {
					img {
						margin-bottom: $padding--xs;
					}

					ul {
						@include bullets;
					}

					blockquote {
						@include paddingTopBottomSm;
						margin: $padding--sm 0;
						border-top: 3px solid color(accent);
						border-bottom: 3px solid color(accent);
						border-left: none !important;

						p {
							&:last-child {
								margin: 0;
							}

							&.title4 {
								margin-bottom: 1.75rem;
							}
						}
					}
				}

				.partners {
					.card-layout {
						margin: $padding--sm 0;
					}

					.card {
						img {
							width: 135px;
							height: auto;
						}

						p:last-child {
							margin-bottom: inherit;
						}
					}
				}

				.section {
					padding: $padding--sm 0;
				}
			}
                    .hs-form-booleancheckbox input {
                            width: unset;
                            margin: 0 5px 0 0;
                            position: relative;
                            top: 2px;
                    }

      .section {
        //original solution of printing out in a normal p tag in template
        //did not work, need this styling regardless
        &.section-top {
        	padding: 0;

        	.p2 {
        		margin-bottom: $padding--lg;
        	}

        	h5 {
        		margin: 0;
        	}
        }

        .p2 {
          p {
            font-size: 2.4rem;
            line-height: calc(35 / 24);
  
            @media (max-width: $mobile) {
              font-size: 2rem;
              line-height: calc(33 / 20);
            }
          }
        }
      }
		}
                /* WRP-1521 - adjusts new bootstrap templates/styles to match existing styles */
                h2.title3{
                    border:0;padding:0;margin:0;
                }
                .wysiwyg h2{
                    font-family: Cambon-Demi, serif;
                    font-weight: 400;
                    font-size: 4.8rem;
                    line-height: 1;
                    border:0;
                    /*margin:inherit;*/
                    /*&.title3{border:0;padding:0;margin:0;}*/
                    @media (max-width: $mobile) {
			font-size: 2.9rem;
                    }
                }
	}

	.attachment {
		display: flex;
		align-items: flex-start;

		@media (max-width: $mobile) {
			flex-direction: column-reverse;
		}

		&:not(:last-child) {
			margin-bottom: $padding--xs;
		}

		.inner {
			padding-left: $padding--sm;

			@media (max-width: $mobile) {
				padding-left: 0;
				padding-bottom: $padding--xs;
			}

			p {
				margin: 0 !important;

				strong {
					font-weight: 500;
				}

				&.author {
					color: color(accent, darkGray);
					font-size: 1.3rem;
					margin: $gap 0;
				}
			}
		}

		.download-link {
			@media (max-width: $mobile) {
				width: 100%;

				.btn {
					width: 100%;
				}
			}
		}
	}
        section.ab7-directory {
            h2.section-title {
                text-align: left;
                margin-left: 0 !important;
            }
        }
	.ab7-directory {
		padding: 0;
	}
}

@mixin indicators() {
	@for $i from 0 through 50 {
		@for $j from 0 through $i {
			&[data-current-question="#{$i}"] {
				.indicator-#{$j} {
					opacity: 1;
				}
			}
		}
	}
}

.page-node-type-quiz {
	background: $grad2;

  header {
  	.column-layout .col:first-of-type {
  		width: auto;
  	}

  	.container--lg.main-nav .logo {
  		@media (max-width: $mobile) {
  			width: 122px;
  			height: 39px;
  		}
  	}

    .menu-buttons, .menu-toggle {
      display: none;
    }
    .quiz-links {
      .live-chat,
      .telephone {
        color: color(white, base);
        padding: 0 1rem;
        font-size: 1.25rem; 

        &:before {
          display: inline-block;
          width: 20px;
          height: 20px;
          position: relative;
          top: 5px;
          right: 5px;
        }
      }
      .live-chat {
        &:before {
          content: url('../imgs/icons/live-chat.svg');
        }
      }
      .telephone {
        &:before {
          content: url('../imgs/icons/telephone.svg');
        }
      }
    }
  }
  .admin {
    display: none;
  }
  footer {
    display: none;
  }
}

.acc-quiz {
	background: $grad2;
	width: 100vw;
	height: calc(100vh - 70px); // height of header
	display: flex;
	position: relative;

	// --- debug
	.debug-center {
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		pointer-events: none;
		padding: 20px;
		color: color(white);
		font-size: 16px;
		z-index: 9999;
		display: none;
	}

  .back-link {
    padding-bottom: 5rem;

    &.absolute {
    	top: 75px;
    	left: 100px;

    	@media (max-width: $mobile) {
    		top: 20px;
    		left: 17.5px;
    	}
    }

    .link {
      display: block;
      width: 100%;
      font-size: 1.25rem;
      text-transform: uppercase;
      text-decoration: underline;
      text-decoration-color: color(accent, peach);
      text-underline-offset: 5px;
      &:before {
        content: url('../imgs/icons/arrow-peach.svg');
        display: inline-block;
        position: relative;
        width: 20px;
        padding-right: 3rem;
        top: -2px;
      }
    }
  }

	.question[data-question-answer="false"] {
		//border: 1px solid red;
	}

	.question[data-question-answer="true"] {
		//border: 1px solid green;
	}

	// --- debug

	.page {
		width: 100vw;
		height: calc(100vh - 70px); // height of header
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		opacity: 0;
		visibility: hidden;
		transition: opacity 1s, visibility 1s;
		background: $grad2; // so that mix blend mode will work when opacity < 1

    .container--sm {
      @media(max-width: $mobile) {
        max-width: 100%;
      }
    }

		&.active {
			pointer-events: auto;
			opacity: 1;
			visibility: visible;

			.question {
				// moved up to prevent clicks on hidden pages
				&.active {
					pointer-events: auto;
					opacity: 1;
					visibility: visible;
					max-height: 2000px;
					transition: opacity 1s, visibility 1s, max-height 0s;
				}
			}
		}

		&.title {
			text-align: center;
			background-image: url('../imgs/logos/blank-shield.svg');
			background-repeat: no-repeat;
	    background-position: 85% $padding--md;
	    background-size: auto calc(100% - $padding--lg);

	    @media (max-width: $mobile) {
	    	background-position: right $padding--lg;
    		background-size: 60% auto;
	    }

			h2 {
				color: color(accent, smoke);
			}

			.subtitle * {
				font-size: 8.3rem;
				line-height: 1;
				font-family: $headlineFont;

				@media (max-width: $mobile) {
					font-size: 3.6rem;
					line-height: calc(39 / 36);
				}
			}
		}

		.overflow-scroller {
			width: 100%;
			max-height: 100%;
			overflow-y: scroll;
			position: relative;
			padding: $padding--md 0;
		}

		&.core.splash .overflow-scroller {
			padding-bottom: $padding--lg;

			@media (min-width: $mobile) {
				padding-top: $padding--lg;
			}
		}

		.splash-cols {
			p {
				&:last-of-type {
					margin-bottom: 0;
				}

				@media (max-width: $mobile) {
					&:not(.eyebrow) {
						margin-bottom: 0;
					}
				}
			}

			.btn {
				margin: $padding--md 0 $padding--lg;
			}

			.img-col {
				position: relative;
				margin-right: $padding--lg;

		    @media (max-width: $mobile) {
		      width: 75%;
		      margin: 0 auto;
		    }

				width: 450px;

				.img-crop {
					position: absolute;
					padding-bottom: calc((660 / 540) * 100%);

					&:nth-of-type(1) {
						position: relative;
						z-index: 1;
					}

					&:nth-of-type(2) {
						top: calc(-1 * $padding--lg);
						left: calc(-1 * $padding--lg);
						mix-blend-mode: multiply;

						@media (max-width: $mobile) {
							top: calc(-1 * $padding--md);
							left: calc(-1 * $padding--md);
						}
					}

					&:nth-of-type(3) {
						top: calc(1 * $padding--lg);
						left: calc(1 * $padding--lg);
						mix-blend-mode: overlay;
						opacity: 0.9;

						@media (max-width: $mobile) {
							top: calc(1 * $padding--md);
							left: calc(1 * $padding--md);
						}
					}
				}
			}
		}
	}

	.question-page {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		@include indicators();

		.indicators {
			display: flex;
			align-items: center;
			justify-content: center;
      margin-bottom: $padding--sm;
      order: -1;

			span {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				display: block;
				background: color(white);

				&:not(:last-child) {
					margin-right: $padding--xs;
				}

				opacity: 0.5;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	.questions {
		position: relative;
		width: 100%;
		height: auto;

		.question {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;

			pointer-events: none;
			opacity: 0;
			visibility: hidden;
			max-height: 0;
			transition: opacity 1s, visibility 1s, max-height 1s 1s;

			&.active {
				position: relative;
			}

			.title3 p {
				@include h3-font-styles();
				margin-bottom: $padding--sm;
			}

			.btn p {
				margin-bottom: 0;
			}

			.answers {
				width: 100%;

        @media (max-width: $mobile) {
          display: flex;
          flex-direction: column;
        }
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr;
				grid-gap: #{$padding--xs};

				[data-answer] {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					&.active {
						border: none;
						background: linear-gradient(45deg, #4200ed 0%, #ff8d7b 100%);
					}
				}
			}

			.nav {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: $padding--sm;
				width: 100%;

				.btn:not(:last-of-type) {
					margin-right: $padding--xs;
				}
			}

			&[data-question-id="0"] {
				.nav [data-prev-question] {
					display: none;
				}
			}

			&[data-question-answer=""] {
				.nav [data-next-question] {
					opacity: 0.5;
					pointer-events: none;
				}
			}
		}
	}

	.quiz-form-modal {
		@media (max-width: $mobile) {
			width: 100%;
		}

		.hbspt-form {
			.hs-fieldtype-checkbox ul.inputs-list.multi-container li {
				width: 100%;
			}

			.hs_submit input[type=submit] {
				color: color(white);
				background-color: color(accent);
				margin: $padding--sm 0;

				@media (max-width: $mobile) {
					margin: $padding--xs 0;
				}

				&:hover {
					background: linear-gradient(135deg, color(accent), color(accent, peach));
				}
			}
		}
	}

	.results {
		background: color(white);

		h1,
		h2,
		h3,
		h4,
		h5,
		p,
		ul li {
			color: color(black);
		}

		.back-link .link {
			color: color(black);
			text-decoration-color: color(accent);

			&:before {
				content: url('../imgs/icons/back-arrow.svg');
			}
		}

		.title1 {
			text-align: center;
			margin-bottom: $padding--lg;

			@media (max-width: $mobile) {
				margin-bottom: $padding--sm;
			}
		}

		.column-layout {
			gap: $padding--sm;
		}

		.results-cols {
			padding-bottom: $padding--lg;
			border-bottom: 1px solid color(gray, rules);

			@media (max-width: $mobile) {
				padding-bottom: $padding--md;
			}
		}

		svg {
			width: 100%;
			height: auto;

			@media (max-width: $mobile) {
				padding: 0 $padding--md;
			}

			.result-arc {
				mix-blend-mode: multiply;
				fill: color(accent, peach);
				cursor: pointer;
				transition: fill 0.5s;

				&:hover {
					fill: color(accent, arctic);
				}
			}

			g {
				cursor: pointer;

				&.mouse:focus {
					outline: none;
				}

				.label {
					fill: color(accent, arctic);
					transition: fill 0.5s;
				}

				path:not(.label) {
					fill: color(black);
					transition: fill 0.5s;
				}

				&:hover {
					.label {
						fill: color(accent, lilac);
					}

					path:not(.label) {
						fill: color(white);
					}
				}
			}
		}

		.chart[data-current="specialization"] {
			svg {
				.result-arc {
					fill: color(accent, arctic);

					&:hover {
						fill: color(accent, peach);
					}

					&.specialization {
						fill: color(accent, peach);
					}
				}
			}

			g.specialization {
				.label {
					fill: color(accent);
				}
				path:not(.label) {
					fill: color(white);
				}
			}
		}

		.chart[data-current="advanced"] {
			svg {
				.result-arc {
					fill: color(accent, arctic);

					&:hover {
						fill: color(accent, peach);
					}

					&.advanced {
						fill: color(accent, peach);
					}
				}
			}

			g.advanced {
				.label {
					fill: color(accent);
				}
				path:not(.label) {
					fill: color(white);
				}
			}
		}

		.chart[data-current="foundation"] {
			svg {
				.result-arc {
					fill: color(accent, arctic);

					&:hover {
						fill: color(accent, peach);
					}

					&.foundation {
						fill: color(accent, peach);
					}
				}
			}

			g.foundation {
				.label {
					fill: color(accent);
				}
				path:not(.label) {
					fill: color(white);
				}
			}
		}

		.chart-sub {
			margin-top: $padding--sm;
			text-align: center;
		}

		.tabs-col {
			border: 1px solid color(gray, rules);
			padding: $padding--md $padding--sm;
			overflow-y: auto;

			@media (max-width: $mobile) {
				padding: $padding--sm $padding--xs;
			}
		}

		.tab-cont {
			position: relative;
			width: 100%;
			height: 0;

			p:last-of-type {
				padding-bottom: $padding--md;
				margin: 0;
			}

			@media (max-width: $mobile) {
				height: auto;
				max-height: 400px;

				p:last-of-type {
					padding-bottom: $padding--sm;
				}
			}

			.tab-item {
				@include hideLayer;
				height: 0;
				visibility: hidden;
				overflow: hidden;
				transition: opacity $animationDuration, height $animationDuration;
			}
		}

		&.active {
			.tab-item {
				&.active {
					@include showLayer;
					visibility: visible;
					height: auto;
				}

				@media (max-width: bp(sm)) {
					&.mobile-active {
						@include showLayer;
						height: auto;
					}
				}
			}
		}

		.callout-blocks {
			padding-top: $padding--lg;

			@media (max-width: $mobile) {
				padding-top: $padding--md;
			}

			.callout-block {
				display: flex;
				gap: 1rem;

				.left {
					flex-basis: calc(100% * 1 / 3);
				}

				.right {
					flex-basis: calc(100% * 2 / 3);
				}

				.eyebrow {
					color: color(accent);
				}
			}
		}
	}
}
.cb7-large-links {
	background-image: $grad2;

	padding-top: $padding--xl;
	padding-bottom: calc($padding--xl - $padding--md);

	@media (max-width: $mobile) {
		padding-top: $padding--lg;
		padding-bottom: calc($padding--lg - $padding--md);
	}

	p {
		text-align: center;
	}

	p.eyebrow--lg {
		margin-bottom: $padding--md;
	}

	.links-container {
		width: 100%;
		overflow: hidden;

		.continuous-scroll {
			position: relative;
			width: 100vw;
			font-family: $headlineFont;
			font-size: 6rem;
			line-height: 1;
			display: flex;
			align-items: center;
			color: color(white);
			margin-bottom: $padding--md;

			@media (max-width: $mobile) {
				font-size: 4rem;
			}

			a {
				padding: 0 20px;
				flex: none;
				position: relative;
				transition: color 0.25s, border-bottom 0.25s;

				&:after {
					content: "";
					border-bottom: 4px solid transparent;
					width: calc(100% - 40px);
					position: absolute;
					bottom: 0;
					left: 20px;
				}

				&:hover {
					color: color(accent, peach);

					&:after {
						border-bottom: 4px solid color(accent, peach);
					}
				}
			}
		}
	}
}

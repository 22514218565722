.cb9-features-resources {
	padding-top: $padding--xl;
	padding-bottom: $padding--xl;

	@media (max-width: $mobile) {
		padding-top: $padding--lg;
		padding-bottom: $padding--md;
	}

	.filters {
		margin: $padding--lg 0 $padding--md;
	}

	.underline-link {
		font-weight: bold;

		@media (max-width: $mobile) {
			margin-top: $padding--sm;
		}
	}

	.cards {
		.card.resource {
			&:nth-of-type(1) {
				grid-column: 1/3;
				grid-row: 1/3;

				@media (max-width: $mobile) {
					grid-column: 1/2;
					grid-row: 1/2;
					border-right: none;
				}

				position: relative;
				&:after {
					width: 1px;
					background: color(grey, rules);
					height: 100%;
					position: absolute;
					top: 0;
					right: calc(-0.5 * $padding--sm);
					content: "";
					display: block;
				}
			}
		}

		&.flip {
			.card.resource {
				&:nth-of-type(1) {
					grid-column: 3/5;
					grid-row: 1/3;

					@media (max-width: $mobile) {
						grid-column: 1/2;
						grid-row: 1/2;
						border-right: none;
					}

					position: relative;
					&:after {
						width: 1px;
						background: color(grey, rules);
						height: 100%;
						position: absolute;
						top: 0;
						right: auto;
						left: calc(-0.5 * $padding--sm);
						content: "";
						display: block;
					}
				}
			}
		}
	}

	[data-swiper="mobile-only"] {
		@media (max-width: $mobile) {
			.cards {
				display: flex;
				grid-template-columns: inherit;
				grid-gap: inherit;
			}

			.card.resource:nth-of-type(1) {
				&:after {
					display: none;
				}
			}
		}
	}
}

.cb9-features-resources,
.ab3-resource-grid {
	.filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 $padding--md;

		@media (max-width: $mobile) {
			flex-direction: column-reverse;
		}

		.col {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			justify-self: flex-end;

			@media (max-width: $mobile) {
				flex-direction: column;
				width: 100%;
			}

			a {
				@media (max-width: $mobile) {
					&:not(:last-child) {
						margin-bottom: 1rem;
					}
				}

				@media (min-width: $mobile) {
					&:not(:last-child) {
						margin-right: $gap;
					}
				}
			}
		}
	}

  .no-link {
    .filters {
      justify-content: flex-end;
      .see-all-link {
        display: none;
      }
    }
  }

	.cards {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: #{$padding--sm};

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

		.card {
		}
	}
}

.resource {
	.img-crop {
		padding-bottom: calc((300 / 560) * 100%);
		margin-bottom: $padding--xs;
	}

	.save {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 9;
		background: color(white);
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
                cursor: pointer;
		img {
			width: 18px;
			height: auto;
			position: Relative;
			top: auto;
			left: auto;
		}
	}

	p {
		margin: 0 0 $padding--xs;
	}

	p.eyebrow {
		color: color(accent);
	}

	p.author,
	p.date {
		font-size: 1.3rem;
		color: color(accent, darkGrey);
	}

	p.title, p.title5 {
		a {
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: all 0.5s;

			&:hover {
				text-decoration-color: color(accent, lilac);
			}
		}
	}

  &.featured {
    p.title {
      @include h3-font-styles();
    }
  }

  &.small {
    p.title {
      @include h5-font-styles(); 
    }
    p.intro-text {
      display: none;
    }
  }

  &.featured,
  &.small {
    .resource-link {
      color: color(black);
    }
  }
}

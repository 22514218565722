.page-search {
  .layout-container {
    background-color: #f5f5f5;
  }
}

.ui-menu.search-api-autocomplete-search {
  font-size: 1.6rem;
}

header {
  .utility-nav #block-utility-nav .utilities .search-close-mobile {
    display: none;
  }

  @media(max-width: $mobile) {
    &.search-open {
      background-image: unset;
      background-color: white;

      .menu-toggle {
        color: color(accent, reflexBlue);
      }

      .container--lg.main-nav {
        .logo {
          display: none;
        }
  
        .logo-alt {
          display: block;
        }
      }

      .utility-nav #block-utility-nav .utilities {
        .search-close-mobile {
          display: block;
        }
  
        .search {
          display: none;
        }
      }
    }
  }
} 

.sitewide-search-modal {
  transition: all 0.5s, top 0s, height 0s;
  top: 0 !important; // important tag required for style override
  height: 100vh !important; // important tag required for style override

  &:not(.active) .tab-section .tab-cont .tab-item.active {
    pointer-events: none;
  }

  @media(max-width: $mobile) {
    h2 {
      font-size: 2.5rem;
      margin-bottom: 0;
    }
    .modalContent.scrollable {
      width: 100%;
      height: 100%;
      padding: $padding--sm 0;
      .ab4-sitewide-search {
        padding-top: 40px;
      }
    }
  }
}

.ab4-sitewide-search {
  padding-top: $padding--sm;
  padding-bottom: $padding--sm;

  @media (max-width: $mobile) {
    padding: 2rem;
  }

  .tab-nav {
    border-bottom: 1px solid color(black);

    @media (max-width: $mobile) {
      align-items: flex-start;
    }

    .tab-link {
      position: relative;
      z-index: 8;
      padding: $padding--xs;
      font-size: 1.4rem;
      font-weight: 500;

      @media (max-width: $mobile) {
        width: auto;
        background: #f2f2f2;
        padding: calc($padding--xs/2) $padding--xs;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        opacity: 0;
        background-image: $grad5;
        transition: opacity $animationDuration;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        background: color(accent);
        width: 100%;
        z-index: 9;
        opacity: 0;
        transition: opacity $animationDuration;
      }

      &.active {
        text-decoration: none;

        &:before {
          opacity: 1;
        }

        &:hover {
          &:after {
            display: none;
          }
        }
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .tab-item {
    padding: $padding--md 0 0;
    top: 0;

    @media (max-width: $mobile) {
      padding-bottom: $padding--md;
    }
  }

  .search {
    max-width: 1160px;
    margin: 0 auto;

    .search-title {
      margin-bottom: 0;
    }

    .filters {
      padding: 2rem 0;

      .form--inline, div.d-flex.flex-wrap {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        align-items: center;
        width: 100%;

        &::after {
          content: none;
        }

        @media (max-width: $mobile) {
          gap: 0.5rem;
        }

        .form-type-search-api-autocomplete {
          width: 100%;

          input {
            width: 100%;
            padding: 0 10px;
            height: 40px;
            border: 1px solid color(black, base);
            border-radius: 2px;
            font-size: 16px;
          }
        }
      }
    }

    .result-count,
    .no-results {
      @include p-font-styles();
      padding: 2rem 0;
      font-weight: 500;
    }

    .search-results {
      .search-result {
        @include p-font-styles();
        margin-bottom: $padding--sm;

        a:not(.btn) {
          text-decoration: none;
        }

        .p2 {
          margin-bottom: 1rem;
        }

        .search-link {
          color: color(accent, darkGray);
          overflow-wrap: break-word;
        }
      }
    }

    .pager {
      .pagination {
        display: flex;
        justify-content: center;

        @media (max-width: $mobile) {
          font-size: 1.4rem;
        }

        .pager__item {
          padding: 0 1rem;
          white-space: nowrap;

          @media (max-width: $mobile) {
            padding: 0 0.5rem;
          }

          a {
            color: color(black);
            text-decoration: none;
          }

          &.active {
            font-weight: 500;
            pointer-events: none;
          }

          &--previous {
            margin-right: $padding--lg;

            @media (max-width: $mobile) {
              margin-right: $padding--xs;
            }

            &:before {
              content: url("../imgs/icons/back-arrow.svg");
              margin-right: 0.75rem;
            }
          }

          &--next {
            margin-left: $padding--lg;

            @media (max-width: $mobile) {
              margin-left: $padding--xs;
            }

            &:after {
              content: url("../imgs/icons/forward-arrow.svg");
              margin-left: 0.75rem;
            }
          }
        }
      }
    }
  }
}

/****************************************

BUTTONS

****************************************/

@mixin btnStyle {
	padding: 15px 25px;
	font-size: 1.4rem;
	line-height: 1;
	font-weight: 500;
	border: 0;
	border-radius: 50px;
	color: color(white);
	background-color: color(accent);
	text-decoration: none;
	text-align: center;
	display: block;
	width: fit-content;
	align-items: center;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	z-index: 9;
	transition: border-color $animationDuration, background-color $animationDuration, color $animationDuration;

	@media (max-width: $mobile) {
		width: 100%;
	}

	&::after {
		--size: 0;

		content: "";
		position: absolute;
		left: var(--x);
		top: var(--y);
		width: var(--size);
		height: var(--size);
		background: radial-gradient(circle closest-side, color(accent, peach), transparent);
		transform: translate(-50%, -50%);
		transition: width 0.2s ease, height 0.2s ease;
		z-index: -1;
	}

	&:hover::after {
		--size: 400px;
	}

	&:hover {
		color: color(white);
	}
}

.btn {
	@include btnStyle;

	&.center {
		margin: 0 auto;

		@media (max-width: $mobile) {
			width: 100%;
		}
	}

	&.secondary {
		border: 1px solid color(accent);
		color: color(accent, midnight);
		background-color: transparent;
		background: none;
		padding: 10px 25px;

		&:hover {
			border: 1px solid transparent;
			background-clip: padding-box;
			background-color: color(accent);
			color: color(white);
		}

		&.apply {
			border-radius: 3px;
			padding: 5px 10px 7px 10px;
		}

		&.large {
			padding: 15px 25px;
		}
	}

	&.tertiary {
		color: color(accent, midnight);
		background-color: color(accent, peach);
	}

	&.peach {
		color: color(accent, midnight);
		background-color: color(accent, peach);

		&::after {
			background: radial-gradient(circle closest-side, color(accent, smoke), transparent);
		}
	}

	&.transparent {
		background: none;
		border: 1px solid color(accent, peach);
	}

	&.transparent-peach {
		background: none;
		border: 1px solid color(accent, peach);

		&:hover {
			background-color: color(accent, peach);
			color: color(accent, midnight);
		}

		&::after {
			background: radial-gradient(circle closest-side, color(accent, smoke), transparent);
		}
	}

	&.filter {
		background: #f2f2f2;
		color: color(black);
		
		&:hover {
			background-color: color(accent, arctic);
		}

		&::after {
			background: radial-gradient(circle closest-side, color(accent, mist), transparent);
		}
	}

	&.disabled {
		background: $grad5;
		pointer-events: none;
		border: none;
		color: color(accent, midnight);
	}
}

@mixin underlineLink {
	font-weight: 500;
	line-height: 1.5rem;
	font-size: 1.4rem;
	text-decoration: none;
	padding-bottom: 0.25rem;

	position: relative;

	&.center {
		margin: 0 auto;
		display: table;
		width: auto;
	}

	&::before,
	&::after {
		position: absolute;
		content: "";
		left: 0;
		bottom: -0.1rem;
		display: block;
		width: 100%;
		height: 1px;
		background: color(accent, reflexBlue);
		transition: 1.1s cubic-bezier(0.19, 1, 0.22, 1);
	}

	&::before {
		transform: scaleX(0);
		transform-origin: left;
	}

	&::after {
		transform-origin: right;
		transition-delay: 0.25s;
	}

	&:hover {
		&::before {
			transform: scaleX(1);
			transition-delay: 0.25s;
		}

		&::after {
			transform: scaleX(0);
			transition-delay: 0s;
		}
	}
}
.underline-link {
	@include underlineLink;
}

@mixin navUnderlineLink {
	text-decoration: none;
	padding-bottom: 0.25rem;

	position: relative;

	&::before,
	&::after {
		position: absolute;
		content: "";
		left: 0;
		bottom: -0.1rem;
		display: block;
		width: 0;
		height: 1px;
		background: color(black);
		transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
	}

	&::before {
		transform: scaleX(0);
		transform-origin: left;
	}

	&::after {
		transform-origin: right;
		transition-delay: 0.25s;
	}

	&:hover {
		&::before {
			width: 100%;
			transform: scaleX(1);
			transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1) 0.25s, width 0s 0.25s;
		}

		&::after {
			width: 100%;
			transform: scaleX(0);
			transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1) 0s, width 0s;
		}
	}
}

.arrow-btn {
	display: flex;
	align-items: center;

	.blue-arrow {
		display: none;
	}

	&:before {
		content: "";
		width: 20px;
		height: 2px;
		background: color(black);
		display: block;
		margin-right: -10px;
		transition: width $animationDuration;
	}

	&:hover {
		&:before {
			width: 30px;
			background: color(accent, reflexBlue);
		}

		.black-arrow {
			display: none;
		}

		.blue-arrow {
			display: block;
		}
	}
}

.links {
	display: flex;
	align-items: center;

	@media (max-width: $mobile) {
		flex-direction: column;

		a {
			&:not(:last-child) {
				margin-bottom: $padding--xs;
			}
		}
	}

	&.center {
		width: 100%;
		justify-content: center;
	}

	&.btns {
		a {
			&:first-child {
				@include btnStyle;
			}

			&:not(:last-child) {
				@include btnStyle;
				margin-right: $padding--xs;

				@media (max-width: $mobile) {
					margin-right: 0;
				}
			}
		}

		&.peach {
			a {
				@include btnStyle;
				background: none;
				border: 1px solid color(accent, peach);

				&:hover {
					background-color: color(accent, peach);
					color: color(accent, midnight);
				}

				&::after {
					background: radial-gradient(circle closest-side, color(accent, smoke), transparent);
				}

				&:first-child {
					background-color: color(accent, peach);
					color: color(accent, midnight);
				}

				&:not(:last-child) {
					margin-right: $padding--xs;

					@media (max-width: $mobile) {
						margin-right: 0;
					}
				}
			}
		}
	}

	&.single-btn {
		a {
			&:first-child {
				@include btnStyle;
			}

			&:not(:first-child) {
				@include underlineLink;
			}

			&:not(:last-child) {
				margin-right: $padding--xs;

				@media (max-width: $mobile) {
					margin-right: 0;
				}
			}
		}

		&.peach {
			a:not(:first-child) {
				&:before,
				&:after {
					background: color(accent, peach);
				}
			}

			a:first-child {
				background-color: color(accent, peach);
				color: color(accent, midnight);

				&::after {
					background: radial-gradient(circle closest-side, color(accent, smoke), transparent);
				}
			}
		}
	}
}

.video-link {
	width: 65px;
	height: 65px;
	background: color(white);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform $animationDuration;

	&:hover {
		transform: scale(1.15);
	}

	&:after {
		content: "";
		width: 0;
		height: 0;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: 14px solid color(accent);
	}

	&.center {
		position: absolute;
		top: calc((100% - 65px) / 2);
		left: calc((100% - 65px) / 2);
		z-index: 9;
	}
}

svg.external-link {
    display: inline;
    margin-left: 0.25rem;
    margin-top: -0.25rem;
    width: auto !important; // important tag required for style override
    height: auto !important; // important tag required for style override
}

/****************************************

SWIPER SECTION

include js: 

basic structure: 

****************************************/

.swiper-section {
	position: relative;
	width: 100%;
	height: auto;

	.swiper-nav {
		@include flexCenter;
		@include internalNav;
		position: relative;

		.swiper-link {
			@include internalNavLink;
			padding: 10px;
			cursor: pointer;

			&.active {
				text-decoration: underline;
			}
		}
	}

	.swiper-cont {
		overflow: hidden;
		position: relative;
	}

	.swiper-button-prev {
		background: none;
		width: 50px;
		height: 50px;
		margin: 0;
		top: calc((100% - 50px) / 2);
		left: 5px;

		&:after {
			content: "";
			width: 20px;
			height: 20px;
			border-top: 1px solid #000;
			border-right: 1px solid #000;
			position: absolute;
			top: 13px;
			left: 13px;
			transform: rotate(-135deg);
		}
	}

	.swiper-button-next {
		background: none;
		width: 50px;
		height: 50px;
		margin: 0;
		top: calc((100% - 50px) / 2);
		right: 5px;

		&:after {
			content: "";
			width: 20px;
			height: 20px;
			border-top: 1px solid #000;
			border-right: 1px solid #000;
			position: absolute;
			top: 13px;
			right: 13px;
			transform: rotate(45deg);
		}
	}

	&.light {
		.swiper-button-next:after,
		.swiper-button-prev:after {
			border-color: #fff;
		}
	}

	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		opacity: 0.5;
	}

	.swiper-pagination {
		width: 100%;
		position: relative;
		padding: 5px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 25px;

		.swiper-pagination-bullet {
			margin: 0 5px;
			opacity: 0.5;

			background-color: rgba(0, 0, 0, 255);
			border: 1px solid #fff;
			transition: all 0.5s;

			&.swiper-pagination-bullet-active {
				background-color: #fff;
				border: 1px solid #000;
				opacity: 1;
				width: 10px;
				height: 10px;
			}
		}
	}

	&.inset {
		padding: 0 50px;
	}
        .hidden{
            display:none;
        }
}

/****************************************

SITE SPECIFIC

****************************************/

.swiper-btm-bar {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	justify-content: space-between;
	border-top: 1px solid color(black);
	padding-top: $padding--xs;
        &.hidden{
            display:none;
        }
	p {
		@include eyebrow;
		margin: 0;
	}

	.arrows {
		display: flex;
		align-items: center;
	}

	.prev {
		img {
			transform: rotate(180deg);
		}
	}

	.prev,
	.next {
		transition: opacity $animationDuration;

		&.swiper-button-disabled {
			opacity: 0.3;
		}
	}

	*:not(:last-child) {
		margin-right: $gap;
	}
}

// Flashing on Safari, known issue with swiper and this was the suggested workaround
// https://github.com/nolimits4web/swiper/issues/3527
.swiper-slide {
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}

[data-swiper="mobile-only"] {
	.swiper-btm-bar {
		@media (min-width: $mobile) {
			display: none;
		}
	}
}

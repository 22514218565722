/****************************************

HEADER

****************************************/
$mobileHeight: 150px;

.tbm {
  background-color: transparent;
  position: unset;
  &.tbm--mobile {
    .tbm-nav {
      flex-direction: row;
      background: transparent;
    }
    .tbm-collapse {
      background: transparent;
    }
  }
  @media (max-width: $tablet) {
    &.tbm--mobile {
      .tbm-nav {
        flex-direction: column;
        background: white;
      }
      .tbm-collapse {
        background: white;
      }
    }
  }
}

.tbm-item > a {
  &:focus {
    border: 1px solid black;
  }
}

body.mobile-menu-open,
html.mobile-menu-open {
  @media (max-width: $tablet) {
    overflow: hidden;
    position: relative;
  }
}

body.megamenu-open,
html.megamenu-open {
  @media (min-width: $mobile) {
    position: relative;
  }
}

.header-wrapper {
  background-image: $grad2;
}

header {
  position: relative;
  top: 0px;
  width: 100%;
  z-index: 999;
  background-image: $grad2;

  &.active {
    background-image: unset;
    background-color: white;
  }

  &.modal-up {
    z-index: 1;
  }

  .notif-banner {
    background: $grad5;

    // strict styling on banner to enable centering
    .notif-wrapper.container--lg {
      @media (min-width: 1400px) {
        width: 1400px;
      }
      @media (max-width: 1399px) {
        width: 100%;
      }
    }

    &.hide {
      display: none;
    }

    section.notif-block:not(:first-of-type) {
      border-top: 1px solid white;
    }

    .close {
      position: absolute;
      right: 1rem;
      font-size: 3rem;
      cursor: pointer;
      line-height: 1;
      color: color(black);
    }

    .notif-wrapper {
      min-height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;

      .eyebrow {
        flex-basis: 200px;
        text-align: right;
      }

      @media (max-width: $mobile) {
        .eyebrow {
          display: none;
        }
      }
    }

    p {
      font-size: 1.6rem;
      font-weight: 500;
      margin: 10px;
      @media (max-width: $tablet) {
        font-size: 1.2rem;
      }
    }

    a {
      @include underlineLink;
      font-size: 1.2rem;
    }
  }

  .utility-nav {
    position: relative;
    top: 0;
    background-color: white;
    padding: 0;
    max-width: 100%;
    height: 50px;

    &.hide {
      display: none;
    }

    #block-utility-nav {
      display: flex;
      @media (max-width: $tablet) {
        justify-content: space-between;
      }
      justify-content: flex-end;
      align-items: center;
      height: 50px;
      font-size: 1.25rem;

      .links-for {
        display: flex;
        @media (max-width: $tablet) {
          padding: 0;
        }
        padding: 0 1rem;

        .links-for-label {
          @media (max-width: $tablet) {
            display: none;
          }
          display: block;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        .links-dropdown {
          cursor: pointer;
          position: relative;
          @media (max-width: $tablet) {
            padding-left: 5px;
          }
          padding: 0 1rem;
          .dropdown-init {
            display: flex;
            align-items: center;

            &:after {
              content: "";
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid color(accent);
              margin-left: 5px;
              transition: transform 0.5s;
            }
          }

          .links {
            display: block;
            position: relative;
            z-index: 999;
            max-height: 0;
            overflow: hidden;
            position: absolute;
            background-color: white;
            left: 0;
            top: 30px;
            width: 200px;
            padding: 0;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0);
            transition: padding 0.5s, box-shadow 0.5s, max-height 0.5s;
            z-index: 1001;

            .link {
              a {
                padding: 1rem;
                text-decoration: none;
                color: black;
                font-size: 1.5rem;
              }
            }
          }

          &.show,
          &:focus {
            .dropdown-init {
              &:after {
                transform: rotate(180deg);
              }
            }
            .links {
              max-height: 500px;
              padding: 20px 0;
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
              z-index: 1000;
            }
          }

          @media (min-width: $mobile) {
            &:hover {
              .dropdown-init {
                &:after {
                  transform: rotate(180deg);
                }
              }
              .links {
                max-height: 500px;
                padding: 20px 0;
                box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
      }
      .utilities {
        display: flex;
        justify-content: space-evenly;
        > div {
          padding: 0 1rem;

          &:last-child {
            padding-right: 0;
          }

          > a, button {
            display: flex;
            align-items: center;

            img {
              padding-right: 0.75rem;
              padding-bottom: 0.25rem;
            }

            span {
              @include navUnderlineLink;
            }
          }
        }
        .phone {
          @media (max-width: $tablet) {
            display: none;
          }
          display: block;
        }
      }
    }

    .modal .modalContent {
      background: color(white);
    }
  }

  .main-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media (max-width: $tablet) {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;
      &.active {
        .menu-toggle {
          color: color(accent, base);
        }
      }
    }
  }

  .container--lg.main-nav {
    .logo,
    .logo-alt {
      width: 160px;
      height: 51px;

      @media (max-width: $tablet) {
        width: 129px;
        height: 41px;
      }
    }

    .logo-alt {
      display: none;
    }

    &.active {
      .logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
    }
  }

  .column-layout {
    @media (max-width: $tablet) {
      flex-direction: row;
      position: relative;
    }

    .col {
      &:nth-of-type(1) {
        width: 175px;
        display: flex;
        align-items: center;
      }

      &:nth-of-type(2) {
        width: calc(100% - 175px);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: $tablet) {
          justify-content: flex-start;
          align-items: flex-start;
          overflow: scroll;

          .menu-buttons {
            padding-bottom: $padding--lg;
          }
        }

        .btn {
          &:not(:last-child) {
            margin-right: 10px;

            @media (max-width: $tablet) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }

          @media (max-width: $tablet) {
            &.peach {
              color: color(white);
              background-color: color(accent);
            }

            &.transparent-peach {
              color: color(accent);
              border-color: color(accent);
            }
          }
        }
      }
    }
  }

  .menu-toggle {
    display: none;
    cursor: pointer;

    @media (max-width: $tablet) {
      display: flex;
      color: color(accent, peach);
      font-size: 40px;
      padding-right: 0.5rem;
    }
  }

  .nav-cont {
    @media (max-width: $tablet) {
      position: absolute;
      top: 80px !important; //needs to override js
      left: -100vw;
      visibility: hidden;
      padding: $padding--xs;
      background: color(white);
      width: 100vw !important;
      height: calc(100vh - $mobileHeight);
      display: flex;
      flex-direction: column;
      transition: left 0.5s, visibility 0s 0.5s;

      &.active {
        left: -18px;
        width: calc(100vw + 1px) !important;
        visibility: visible;
        transition: left 0.5s, visibility 0s;
      }
    }

    .menu-buttons {
      display: flex;

      @media (max-width: $tablet) {
        display: block;
        width: 100%;
      }

      .btn {
        min-width: min-content;
        white-space: nowrap;

        @media (min-width: $mobile) and (max-width: $tablet) {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .notif-banner-on ~ .main-nav .nav-cont {
      top: 270px;
      height: calc(100vh - 200px);
    }
  }
}

#block-mainnavigation {
  position: inherit;
  z-index: 999;

  @media (max-width: $tablet) {
    position: relative;
  }

  .contextual-links {
    display: none;
  }

  ul,
  ul li {
    margin: 0;
    padding: 0;
  }

  .btn {
    display: none;
  }

  // nav bar
  ul.level-0 {
    display: flex;
    justify-content: space-around;
    margin-right: 10px;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    //nav bar links
    > li {
      height: 90px;
      padding: 0 10px;
      display: flex;
      align-items: center;

      @media (max-width: $tablet) {
        padding: 0 7px;
      }

      @media (max-width: $tablet) {
        height: auto;
        padding: 10px 0;
      }

      > .tbm-link-container {
        height: unset;
        .tbm-link {
          &:focus {
            background-color: transparent;
          }
        }
        > a,
        > span.level-1 {
          color: color(white);
          text-transform: inherit !important;
          cursor: pointer;
          display: flex;
          align-items: center;
          white-space: nowrap;
          @include navUnderlineLink;

          @media (min-width: $mobile) {
            &::before,
            &::after {
              background: color(accent, peach);
            }

            &[title="Support Our Mission"] {
              color: color(accent, peach);
              margin-top: -2px;
            }
          }
          @media (max-width: $tablet) {
            &[title="Support Our Mission"] {
              padding-bottom: 0.25rem;
            }
          }

          @media (max-width: $tablet) {
            font-size: 1.5rem;
          }

          @media (max-width: $tablet) {
            color: color(black);
            font-family: $headlineFont;
            font-size: 2.4rem;
          }
        }
        .level-1 {
          padding: unset;
        }
      }

      .tbm-no-link.dropdown-toggle {
        text-transform: uppercase;
      }

      .tbm-submenu {
        display: flex;
      }

      @media (min-width: $mobile) {
        &.open span.level-1:after {
          width: 100%;
        }
      }
    }

    > li > .tbm-submenu {
      position: absolute;
      top: 160px;
      max-height: calc(100vh - 160px);
      width: 100vw !important;
      max-width: 100vw; // for some reason without this set to 100% on windows it's wider than viewport
      background: color(white);
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
      padding: $padding--xs;
      overflow: hidden;
      left: -100vw;
      will-change: left;
      visibility: hidden;
      transition: left 0.5s, padding 0.5s, max-height 0.5s, visibility 0s 0.5s;
      overflow-y: auto;
      border-top: 0;
      left: -18px !important;

      @media (max-width: $tablet) {
        top: 0;
        z-index: 99;
        padding-top: 33px !important;
      }

      &.active {
        left: 0;
        visibility: visible;
        transition: left 0.5s, padding 0.5s, max-height 0.5s, visibility 0s;
        z-index: 1000;
      }
    }
    .level-1 {
      border-right: none;
      &:hover {
        background-color: transparent;
      }
      &.open > .tbm-submenu {
        left: 0 !important;
        visibility: visible;
        transition: left 0.5s, padding 0.5s, max-height 0.5s, visibility 0s;
        z-index: 1000;
      }
      > li > .tbm-submenu {
        border-top: 0;
      }
    }
    .level-2.tbm-item--has-flyout:not(.open) {
      > .tbm-submenu {
        display: none !important;
      }
    }
    .level-3 {
      a {
        max-width: 100%;
      }
      .tbm-link-container {
        padding-right: 5px;
      }
    }

    //mega container
    .tbm-submenu {
      .close {
        position: absolute;
        top: 26px;
        right: 20px;
        font-size: 48px;
        line-height: 0;
        cursor: pointer;
        color: color(black);
        opacity: 1;
        font-weight: normal;
        @media (max-width: $tablet) {
          top: 13px;
        }
      }

      .mega-dropdown-inner {
        display: flex;
        width: 100%;
        height: 100%;

        > .tbm-row {
          display: flex;
          width: 100%;
          max-width: 1400px;
          margin: 0 auto;

          @media (max-width: $tablet) {
            flex-direction: column;
          }

          .tbm-column {
            display: flex;
            flex-direction: column;

            // left column of mega
            &:nth-child(1) {
              width: calc(100% - 450px);
              padding-right: $padding--md;
              padding-left: 2rem;
              padding-top: 2rem;
              padding-bottom: 2rem;

              @media (max-width: $tablet) {
                width: 100%;
                padding-right: 0;
                padding: 0;
              }
            }

            &:nth-child(2) {
              width: calc(450px);
              padding: 0 0 0 $padding--md;
              border-left: $mainRule;

              @media (max-width: $tablet) {
                width: 100%;
                padding: 0 0 $padding--lg;
                border-left: 0;
              }

              h2 {
                display: none;
              }

              .paragraph {
                padding: $padding--xs 0;

                &:not(:first-of-type) {
                  border-top: $mainRule;
                }
              }

              .paragraph--type--megamenu-content-links {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                a {
                  @include underlineLink;
                  color: color(black);
                  font-size: 1.6rem;

                  @media (max-width: $tablet) {
                    font-size: 1.8rem;
                  }

                  &:not(:last-of-type) {
                    margin-bottom: $padding--xs;
                  }
                }

                &:not(.underline) {
                  a {
                    font-weight: 100;
                    @include navUnderlineLink;
                  }
                }
              }

              .paragraph--type--megamenu-featured-content {
                @media (max-width: $tablet) {
                  display: none;
                }

                .column-layout {
                  @media (max-width: $tablet) {
                    flex-direction: column;
                  }

                  @media (min-width: $mobile) {
                    align-items: flex-start;
                  }

                  .col {
                    &:nth-of-type(1) {
                      width: 135px;
                      flex-direction: column;
                      justify-content: flex-start;

                      @media (max-width: $tablet) {
                        width: 100%;
                      }

                      img {
                        width: 100%;
                        height: auto;

                        @media (min-width: $mobile) {
                          width: 136px;
                          height: 157px;
                          object-fit: cover;
                        }
                      }
                    }

                    &:nth-of-type(2) {
                      width: calc(100% - 135px);
                      padding-left: $padding--xs;
                      flex-direction: column;
                      align-items: flex-start;

                      @media (max-width: $tablet) {
                        width: 100%;
                        padding-left: 0;
                        margin-top: $padding--xs;
                      }

                      .eyebrow {
                        color: color(accent);
                      }

                      > *:not(a) {
                        margin-bottom: 5px;
                      }
                    }
                  }
                }
              }
            }

            //accordian level
            ul.level-1 li.level-2 {
              &:not(:last-child) {
                margin-bottom: $padding--xs;
              }
              > .tbm-link-container {
                > span,
                > a {
                  font-size: 2.3rem;
                  line-height: 1.3;
                  font-family: $headlineFont;
                  text-transform: inherit;
                  cursor: pointer;
                  transition: color 0.25s;

                  @media (max-width: $tablet) {
                    font-size: 2.1rem;
                  }

                  &:hover {
                    color: color(accent);
                  }
                }
                .tbm-link {
                  padding: unset;
                  &:focus {
                    color: color(accent);
                  }
                }
              }

              > .tbm-link-container > a {
                color: color(black);
                text-decoration: none;
                display: flex;
                align-items: center;

                &:after {
                  content: "";
                  width: 30px;
                  height: 22px;
                  margin-left: 10px;
                  display: block;
                  background-size: 100% auto;
                  background-repeat: no-repeat;
                  background-image: url("../imgs/icons/blue-arrow.svg");
                  opacity: 0;
                  transition: opacity 0.5s;
                  flex: none;

                  /* 		@media (max-width: $tablet) {
										opacity: 1;
									} */
                }

                &:hover:after,
                &:focus:after {
                  opacity: 1;
                }
              }

              .dropdown-toggle,
              .tbm-toggle {
                display: flex;
                align-items: center;

                @media (max-width: $tablet) {
                  display: inline;
                }

                .caret {
                  width: 12px;
                  height: 2px;
                  display: block;
                  background: color(accent);
                  flex: none;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 10px;
                  opacity: 0;
                  transition: background 0.25s, opacity 0.25s;

                  @media (max-width: $tablet) {
                    opacity: 1;
                  }

                  &:after {
                    content: "";
                    width: 2px;
                    height: 12px;
                    display: block;
                    background: color(accent);
                    opacity: 0;
                    transition: opacity 0.5s;

                    @media (max-width: $tablet) {
                      opacity: 1;
                    }
                  }
                }

                &:hover,
                &:focus {
                  .caret,
                  .caret:after {
                    background: color(accent);
                    opacity: 1;
                  }
                }

                &.active {
                  .caret {
                    opacity: 1;
                    background: color(black);
                  }

                  &:hover,
                  &:focus {
                    .caret {
                      background: color(accent);
                    }
                  }

                  .caret:after {
                    opacity: 0;
                  }
                }
              }
              &.open {
                .dropdown-toggle,
                .tbm-toggle {
                  .caret {
                    opacity: 1;
                    background: color(black);
                    &::after {
                      opacity: 0;
                    }
                  }
                  &:hover,
                  &:focus {
                    .caret {
                      background: color(accent);
                    }
                  }
                }
              }
            }

            .level-2 .tbm-row,
            .level-2 .tbm-row .tbm-column {
              width: 100%;
              padding: 0;
            }

            ul.tbm-subnav {
              // accordion children
              ul.tbm-subnav {
                max-height: 0;
                overflow: hidden;
                padding: 0;
                transition: padding 0.5s, max-height 0.5s;
              }

              li {
                ul.tbm-subnav {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;

                  li {
                    width: 50%;

                    @media (max-width: $tablet) {
                      width: 100%;
                    }

                    @media (max-width: $tablet) {
                      font-size: 1.8rem;
                    }

                    a {
                      width: auto;
                      text-decoration: none;
                      color: color(black);
                      flex: none;
                      padding-bottom: 0;
                      padding-top: 0;
                      display: inline-block;

                      &:hover {
                        color: color(accent);
                      }

                      @media (min-width: $xLarge) {
                        margin-right: $gap;
                      }
                    }
                  }
                }
              }
            }

            li.level-2 {
              > .tbm-submenu {
                display: none;
              }
              ul.tbm-subnav.level-2 {
                max-height: 0;
                padding: 0;
                overflow: hidden;
                transition: padding 0.5s, max-height 0.5s;
              }
              &.open {
                > .tbm-submenu {
                  display: block;
                }
                > .tbm-submenu {
                  position: unset;
                  border: unset;
                  box-shadow: unset;
                }
                ul.tbm-subnav.level-2 {
                  padding: $padding--xs 0 $padding--sm;
                  max-height: 100vh;
                  &.level-2 {
                    flex-direction: column;
                    max-height: 250px;
                    @media (max-width: $tablet) {
                      max-height: unset;
                    }
                    @media (min-width: 1201px) and (max-width: 1310px) {
                      max-height: 300px;
                    }
                    @media (min-width: 1350px) {
                      max-height: 240px;
                    }
                  }
                }
              }
            }

            .tbm-column-inner {
              display: flex;
              flex-direction: column;

              .block {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }

      .overview {
        font-weight: bold;
      }
    }
    .level-1:not(.open) {
      .tbm-submenu .mega-dropdown-inner>.tbm-row .tbm-column:nth-child(2) .paragraph--type--megamenu-content-links {
        a:before,
        a:after {
          display: none;
        }
      }
    }
  }
}

header .notif-banner-on ~ .main-nav {
  #block-mainnavigation ul.level-0 > li > .tbm-submenu {
    top: 210px;
    max-height: calc(100vh - 210px);

    @media (max-width: $tablet) {
      top: 0 !important; // required to override JS styles
      max-height: calc(100vh - 200px);
      left: -1.75rem !important;
    }
  }
}

.admin {
  margin-left: 20px;
  padding: 20px 0;
  .tabs--primary, .primary.nav-tabs {
    li {
      display: inline-block;
    }
  }
}

body {
  &:not(.page-node-type-quiz) {
    header {
      .quiz-links {
        display: none;
      }
    }
  }
  &.page-node-type-quiz {
    header {
      .utility-nav {
        display: none;
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.user-logged-in {
  header.banner {
    z-index: 99;
  }
}

.tbm.tbm--mobile {
  .tbm-collapse.always-show {
    visibility: visible;
    height: auto !important;
    display: block;
    position: relative;
    padding-top: 0;
  }
  .tbm-nav {
    background-color: transparent;
  }
  .tbm-item {
    border-top: none;
  }
  .tbm-button {
    display: none;
  }
}

.page-node-type-company-landing-page {
  .nav-cont {
    .menu-buttons {
      .btn.peach {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
.cb29-comparison-chart {
	@include paddingTopBottom;
	background: color(white);

	.mobile {
		margin-bottom: $padding--xs;
	}

	table {
		td img, th img {
			margin: 0 auto;
		}

		tr th:not(.col_0) {
			text-align: center;
		}
	}

	.tbl-cont {
		@media (max-width: $mobile) {
			overflow-x: scroll;
			width: 100%;
			padding-bottom: $padding--xs;

			/* width */
			&::-webkit-scrollbar {
				width: 6px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				background: #e0e0e0;
				border-radius: 100px;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: color(accent);
				border-radius: 100px;
			}
		}

		@media (max-width: $mobile) {
			th.row_0.col_0 * {
				display: none;
			}
		}

		.table-responsive {
			overflow: visible;
		}
		
	}

	.tbl {
		@media (max-width: $mobile) {
			width: calc(170px * 5);
		}

		.rw {
			@media (max-width: $mobile) {
				width: auto;
			}

			&.header {
				.col {
					justify-content: space-between;
					min-height: 250px;

					@media (max-width: $mobile) {
						min-height: auto;
					}

					&:nth-of-type(2) p {
						color: color(accent);
					}
				}

				img {
					width: 80%;
					height: auto;

					@media (max-width: $mobile) {
						display: none;
					}
				}

				p {
					text-align: center;
				}
			}

			&:not(.header) {
				.col {
					padding: $padding--sm;
				}
			}

			.col {
				@media (max-width: $mobile) {
					width: 170px;
					flex: none;
				}

				&:nth-of-type(1) {
					align-items: flex-start;
					min-width: 425px;

					@media (max-width: $mobile) {
						min-width: auto;
						width: 170px;
						padding: $padding--sm $padding--xs $padding--sm 0;
					}
				}

				p {
					margin: 0;
				}
			}
		}
	}

	.chart-sub {
		font-weight: bold;
		text-align: center;
		margin: 2rem 0 0;
	}
        a.btn{
            margin-top:50px;
        }
}

.ab3-resource-grid {
  padding-bottom: $padding--xl;

  @media (max-width: theme("screens.md")) {
    padding-bottom: $padding--lg;
  }

  .views-infinite-scroll-content-wrapper {
    display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: #{$padding--sm};

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}

    &:before {
      content: none;
    }
    width: 100%;
  }
  
  
  .promoted {
    background: $grad5;
    padding: $padding--xl $padding--lg;

    @media (max-width: $mobile) {
      padding: $padding--lg $padding--xs;
    }
  }

  .grid {
    margin-top: $padding--lg;

    @media (max-width: $mobile) {
      margin-top: $padding--md;
    }

    .result-count {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: $padding--xs;
    }

    .filters {
      &.search {
        margin: 0 0 0 0;
        .col {
          width: 100%;
          justify-content: center;

          form {
            width: 100%;

            .form-inline, .flex-wrap {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              margin-bottom: $padding--md;
          
              @media (max-width: $mobile) {
                flex-direction: column;
              }
          
              .form-item, .js-form-item {
                display: flex;
                width: 100%;
                padding-right: $padding--xs;
          
                @media (max-width: $mobile) {
                  padding-right: 0;
                  margin-bottom: 2rem;
                  flex-direction: column;
                }

                @media (min-width: $mobile) {
                  align-items: center;
                  gap: 1rem;
                }
          
                label {
                  font-weight: 500;
                  font-size: 1.4rem;
                  margin-bottom: 5px;
                  white-space: nowrap;
                }
                .select-wrapper {
                  width: 100%;
                }
                input,
                select {
                  width: 100%;
                  background-color: color(white, base);
                  font-size: 1.5rem;
                  border: 1px solid color(black, base);
                }

                input {
                  padding: 8px 8px;
                }
                select {
                  padding: 10px 10px;
                }
              }
          
              .form-actions {
                display: flex;
                align-items: center;
                gap: 2rem;

                @media (max-width: $mobile) {
                  padding-top: 12px;
                  flex-direction: column;
                  width: 100%;
                }
          
                .btn {
                  .icon {
                    display: none;
                  }
          
                  &[data-drupal-selector="edit-reset"] {
                    background: none;
                    color: color(black);
                    padding: 0;
                    margin-right: 0;
                    border-radius: 0;
                    border: none;
                    align-items: inherit;
                    overflow: inherit;
                    display: block;

                    &::after {
                      top: auto;
                      transform: none;
                    }

                    @include underlineLink;

                    @media (max-width: $mobile) {
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }


      }
      &.buttons {
        justify-content: flex-end;
      }
    }
  }

  .card {
    &.featured {
      display: flex;
      align-items: center;
      gap: $padding--md;

      @media (max-width: $mobile) {
        display: block;
      }

      .col {
        &:first-child {
          @media (min-width: $mobile) {
            width: 50%
          }

          @media (max-width: $mobile) {
            padding-bottom: $padding--md;
          }
        }

        &:nth-child(2) {
          @media (min-width: $mobile) {
            width: 40%;
          }
        }

        p:last-of-type {
          margin-bottom: 0;
        }
      }

      .img-crop {
        margin-bottom: 0;
      }

      p.title, .resource-link {
        color: color(accent, midnight);
      }
    }

    &.small {
      .author {
        margin: 0 0;
      }
    }
  }

  .pager__item {

		.button {
      @extend .btn, .secondary;
			margin: 0 auto;

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}
}

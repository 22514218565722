.ab5-events-plugin {
	@include paddingTopBottom;

	.calendar-view {
		padding: 20px 20px;
		border: 1px solid color(gray, rules);
		border-top: 2px solid color(accent, base);

		.fc-left {
			button {
				border-color: color(accent, base);
				background: color(accent, base);

				&.fc-today-button {
					&:disabled {
						background: color(accent, mist);
						border-color: color(accent, mist);
					}
				}
			}
		}
		.fc-right {
			button {
				border-color: color(accent, calendarBlue);
				background: color(accent, calendarBlue);
				&.fc-button-active {
					border-color: color(accent, base);
					background: color(accent, base);
				}
			}
		}

		.fc-view {
			.fc-day-header {
				font-weight: normal;
                                 background:transparent;
                            color: color(black);
			}

			.fc-day-grid {
				.fc-day-top.fc-other-month {
					opacity: 1;
				}
			}
			.fc-today {
				background: color(accent, mist);
			}
                    thead > tr, tr {
                        background: transparent;
                        border:none;
                    }
                    table > tr > th {
                        background:transparent;
                        color: color(black);
                    }
                    table {
                        margin:0;
                    }
		}
                
	}

	.fc-header-toolbar {
		@media (max-width: $mobile) {
			display: flex;
			flex-direction: column;

			.fc-center {
				margin: $padding--xs 0;
			}
		}
	}
      
}

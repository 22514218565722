.cb25-program-list {
	@include paddingTopBottom;
	background-image: $grad5;

	.programs {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-gap: $padding--xs;
		margin-top: $padding--lg;

		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			margin-top: $padding--md;
		}
	}

	.btn.center {
		margin-top: $padding--md;
	}

	.card {
    &.topbar-mastersdegreesgraduateprograms {
      border-top: 5px solid color(accent, peach);
    }

    &.topbar-mastersdegreeprogram {
      border-top: 5px solid color(accent, peach);
    }
    
    &.topbar-certificateprogram {
	    border-top: 5px solid color(accent, peach);  
	  }

    &.topbar-continuingeducationcourses {
      border-top: 5px solid color(accent, sky);
    }

    &.topbar-continuingeducation {
      border-top: 5px solid color(accent, sky);
    }

    &.topbar-consumerfinancialeducation {
    	border-top: 5px solid color(accent, sky);  
  	}
    
    &.topbar-professionaldesignationscertifications {
      border-top: 5px solid color(accent, lilac);  
    }

    &.topbar-professionaldesignationscertificates {
      border-top: 5px solid color(accent, lilac);  
    }

    &.topbar-ce {
      border-top-width: 10px;
    }

    &.list {
      &:first-child,
      &:last-child {
        .img-banner  {
          height: 230px;
          background-size: cover;
          background-position: center center;
        }
      }

      @media (min-width: $mobile) {
      	&:first-child {
	      	grid-row: span 2;
	      }

	      &:last-child {
	      	grid-column-start: 2;
	      	grid-row: 3 / span 2;
	      }
	      &:only-child {
	      	grid-column-start: 1;
	      	grid-row: 3 / span 2;
	      }
				&:nth-child(2):last-child {
					grid-row: span 2;
				}
	    }
    }
    &.grid {
      .img-banner {
        height: 230px;
        background-size: cover;
        background-position: center center;

        @media (max-width: $mobile) {
          height: 300px;
        }
      }
    }

		.btm {
			@include paddingAllSidesSm;
			display: flex;

			@media (max-width: $mobile) {
				flex-direction: column;
				padding: $padding--xs;
			}

			p {
				margin: 0;
			}

			p.program-title {
				font-weight: 500;
				margin: $padding--xs 0;
			}

			.col {
				&:nth-of-type(1) {
					width: calc(100% - 140px);
					padding-right: $padding--lg;

					@media (max-width: $mobile) {
						width: 100%;
						padding-right: 0;
					}
				}

				&:nth-of-type(2) {
					width: 140px;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					justify-content: flex-start;

					@media (max-width: $mobile) {
						width: 100%;
						flex-direction: row;
						align-items: center;
						justify-content: space-around;
						margin-top: $padding--xs;
					}

					.btn {
						margin-bottom: $padding--xs;

						@media (max-width: $mobile) {
							margin-bottom: 0;
							width: auto;
						}
					}
				}
			}
		}
	}

	.programs.count-3 .card.list {
		@media (min-width: $mobile) {
			&:last-child {
				grid-row: 2 / span 2;
			}
		}
	}

	.programs.count-2 .card.list {
		@media (min-width: $mobile) {
			&:last-child {
				grid-row: 1 / span 2;
			}
		}
	}

	.programs.count-1 .card.list {
		@media (min-width: $mobile) {
			&:last-child {
				grid-column-start: 1;
			}
		}
	}
}

.cb13-3-column-tabs {
	// see .cb4-related-posts.scss for shared styles
	background: color(white);

	.content-card-link a {
		@include underlineLink;
	}

	&.charcoal {
		background: color(accent, charcoal);

		.card .btm {
			a.underline-link, .content-card-link a {
				text-decoration: none;

				&:before,
				&:after {
					background: color(white);
				}

				&:hover {
					color: white;
				}
			}
		}
	}

	&:not(.charcoal) {
		h2,
		p,
		p.eyebrow--lg,
		.tab-card .btm p.eyebrow--lg,
		a {
			color: color(black) !important;
		}
	}

	.column-layout {
		margin: 0;

		.col {
			background: none;
		}
	}

	.white-text {
		a {
			color: color(white);
		}
	}

	.card-layout.thirds .card {
		width: calc((100% - (#{$gap} * 8)) / 3);
		margin-bottom: calc(#{$gap} * 4);

		@media (max-width: theme("screens.md")) {
			width: 100%;
		}

		&:not(:nth-of-type(3n + 3)) {
			margin-right: calc(#{$gap} * 4);

			@media (max-width: theme("screens.md")) {
				margin-right: 0;
			}
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	.card {
		.btm {
			background: none;
			padding: $padding--md 0;

			.title3 {
				border-bottom: 1px solid color(gray);
				padding-bottom: $padding--sm;
				margin-bottom: $padding--sm;
			}

			p.eyebrow--lg {
				color: color(accent, mist);
			}

			.read-more a.underline-link {
				text-transform: uppercase;
				text-decoration: none;

				&:before,
				&:after {
					background: color(accent, peach);
				}
			}
		}
	}

	.podcast-section {
		border-top: 1px solid color(black);
		padding-top: $padding--md;

		&.column-layout {
			.col:nth-child(2) {
				@media (max-width: $mobile) {
					margin-top: $padding--md;
				}
			}
		}
	}
}

/****************************************

DEBUG

****************************************/

/*
.col {
	border: 1px solid rgba(0, 0, 0, 0.25);
}

.cont,
.container {
	border: 1px solid rgba(0, 0, 255, 0.25);
}

.module {
	position: relative;
	min-height: 25px;
}
*/

/* Hides the admin View | Edit | Delete options for the "Authenticated Tester" role */
.user-logged-in:not(.toolbar-fixed) .admin {
	display: none;
}

.debug-title {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	color: #fff;
	border: 1px solid red;
	padding: 5px 10px;
	z-index: 9;
	font-size: 14px;
}

/*	Shows a debug title, similar to CB labels, for the node type of the current node.
	Only shown to logged-in users - i.e. admin, content editor, and authenticated tester. */
.page-debug-title {
	position: sticky;
	z-index: 10000;
}

.user-logged-in.toolbar-fixed .page-debug-title {
	top: 35px;
}

// Show debug titles on node preview
html[data-once="node-preview"] .debug-title {
	display: block;
}

body.user-logged-in .debug-title {
	display: block;
}

.placeholder {
	color: red;
	border: 1px solid red;
	font-size: 18px;
	text-transform: uppercase;
	padding: 5px;
}

.to-do {
	opacity: 0.5;
	padding: 0;

	.debug-title {
		position: relative;
		top: auto;
		left: auto;
		border: none;
		border-bottom: 1px solid white;
	}

	&.phase-II {
		background: rgba(0, 0, 0, 255);

		.debug-title {
			color: rgba(255, 255, 255, 0.25) !important;
		}
	}
}

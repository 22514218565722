.ab1-home-hero {
	@include paddingTopBottom;
	background-image: $grad2;

  &.perz-banner {
    padding-top: 0;
    padding-bottom: 0;
    .single-btn a.btn:not(:first-child) {
      padding: 0;
      border-radius: 0;
      overflow: visible;
      &:after {
        transform: none;
        &:hover {
          transform: scaleX(0);
        }
      }
    }
  }

  .column-layout {
    @media (max-width: $mobile) {
      flex-direction: column-reverse;
    }
    .main-col {
      @media (max-width: $mobile) {
        width: 100%;
        padding-right: 0;
        margin-top: 3rem;
      }
      width: calc(100% - 540px);
      z-index: 2;
      padding-right: $padding--sm;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
  
      h1 {
        text-transform: uppercase;
        @media (max-width: $mobile) {
          font-size: 4rem;
          text-align: center;
        }
        font-size: 9rem;
        margin-bottom: $padding--lg;
  
        span:nth-child(2) {
          font-size: 0.7em;
        }
      }
  
      .underline-link {
        @media (max-width: $mobile) {
          font-size: 2rem;
          margin: 0 auto;
          padding-top: 3rem;
          border-bottom: 2px solid color(accent, peach);
          text-align: center;
        }
        cursor: pointer;
  
        &:before,
        &:after {
          background: color(accent, peach);
        }
      }
  
      p.p2 {
        @media (max-width: $mobile) {
          text-align: center;
        }
        max-width: 500px;
        font-size: 2rem;
        margin-bottom: 5rem;
      }
  
      .card-layout {
        @media (min-width: $mobile) {
          max-width: 750px;
        }
  
        .card {
          width: calc((100% - #{$padding--xs}) / 2);
          margin-bottom: $padding--xs;
          display: flex;

          @media (max-width: $mobile) {
            align-items: center;
          }
          
          align-items: flex-start;
  
          @media (max-width: theme("screens.xl")) {
            width: calc((100% - #{$padding--xs}) / 2);
            margin-bottom: $padding--xs;
          }
  
          @media (max-width: $mobile) {
            width: 100%;
          }
  
          &:not(:nth-of-type(even)) {
            margin-right: $padding--xs;
  
            @media (max-width: theme("screens.xl")) {
              margin-right: $padding--xs;
            }
  
            @media (max-width: $mobile) {
              margin-right: 0;
            }
          }
  
          &:last-of-type {
            margin-right: 0;
          }
  
          .checkbox {
            width: 19px;
            height: 18px;
            background: color(white);
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          }
  
          p {
            line-height: 1.2;
            font-size: 1.3rem;
            margin: 0;
            width: calc(100% - 17px);
  
            @media (max-width: $mobile) {
              font-size: 1.6rem;
              line-height: calc(26 / 16);
            }
          }
  
          &.active {
            .checkbox {
              &:before {
                content: "";
                width: 1px;
                background: color(black);
                height: 13px;
                display: block;
                margin-left: 4px;
                transform: rotate(45deg);
              }
  
              &:after {
                content: "";
                width: 1px;
                background: color(black);
                height: 5px;
                display: block;
                margin-left: 5px;
                transform: rotate(-45deg);
                position: absolute;
                bottom: 3.5px;
                left: -0.5px;
                z-index: 9;
              }
            }
          }
        }
      }
  
      .opps {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-height: 0;
        overflow: hidden;
        transition: max-height $animationDuration;
  
        .card-layout {
          justify-content: flex-start;
        }
  
        &.active {
          max-height: 1000px;
        }
      }
  
      .select {
        .card {
          background: color(accent);
          padding: $padding--xs;
          cursor: pointer;
          transition: transform $animationDuration;
  
          &:hover {
            transform: scale(1.025);
          }
        }
      }
  
      .response {
        width: 100%;

        .title4 {
          @media (max-width: $mobile) {
            margin: 0 auto;
          }

          @media (min-width: $mobile) {
            max-width: calc(100% - $padding--xl);
          }
        }

        .card-layout {
          width: 100%;
        }

        .card {
          flex-direction: column;
          padding-bottom: $padding--xs;
          display: none;
  
          &.active {
            display: flex;
            @media (max-width: $mobile) {
              p {
                text-align: center;
              }
            }
          }
  
          a {
            margin-bottom: $gap;
          }
        }
      }
  
      .links {
        @media (max-width: $mobile) {
          width: 100%;
        }
        .btn {
          &:not(:first-child) {
            @media (max-width: $mobile) {
              border: 1px solid color(accent, peach);
              border-radius: 50px;
              padding: 15px 25px;
              &:before, &:after {
                content: none;
              }
            }
            background: none;
            &:hover {
              color: #fff;
            }
          }
        }
      }

      .signup-form {
        margin-top: $padding--lg;
        max-width: 700px;
        width: 100%;

        @media (max-width: $mobile) {
          margin-top: $padding--md;

          .title5 {
            text-align: center;
          }
        }

        input[type="text"], input[type="email"] {
          background: none;
          border: none;
          border-bottom: 2px solid white;
          padding-left: 0;
          color: white;
        }

        input[type="submit"] {
          margin: 0;
        }
      }
    }
  }


	.img-col {
		position: relative;
    @media (max-width: $mobile) {
      width: 75%;
      margin: 0 auto;
    }

		width: 540px;

		.img-crop {
			position: absolute;
			padding-bottom: calc((660 / 540) * 100%);
/* 			top: 0;
			left: 0; */

			&:nth-of-type(1) {
				position: relative;
				z-index: 1;
			}

			&:nth-of-type(2) {
				top: calc(-1 * $padding--md);
				left: calc(-1 * $padding--xl);
        background: $grad2;

        img {
          mix-blend-mode: multiply;
        }
			}

			&:nth-of-type(3) {
				top: calc(1 * $padding--md);
				left: calc(1 * $padding--md);
        background: $grad2;
				
        img {
          mix-blend-mode: overlay;
          opacity: 0.9;
        }
			}
		}
	}
  @keyframes fadeIn {
    0% { 
      opacity: 0; 
    }
    100% { 
      opacity: 1; 
    }
  }
  
  @keyframes slide {
    0% {
      left: 4%; 
      margin-top: 3%;
      opacity: 0;
    }
    100% {
      left: 0;
      margin-top: 0;
      opacity: 1;
    }
  }
  
  @keyframes fill {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100%;
      opacity: 1;
    }
  }
  
  .fill {
    overflow: hidden;
    animation: fill 1.5s;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .opps {
    animation: fadeIn 3s;
  }
  
  .fade-in {
    animation: fadeIn 2s;
  }
  
  .img-col {
    animation: slide 1.5s;
  }
}
/****************************************

FOOTER

****************************************/

footer {
	#block-footer-main {
		padding-top: $padding--lg;
		border-top: 1px solid color(gray, rules);
	}

	p {
		margin-bottom: 0;
		line-height: 1.4;
		font-size: inherit;
	}

	.footer-link-container {
		@media (min-width: theme("screens.lg")) {
			display: block;
			columns: 3;
			column-gap: 2rem;
		}

	}

	.footer-link-group {
		padding-bottom: 1rem;
    display: block;
    overflow: hidden;
    break-inside: avoid-column;
	}

	.footer-link-header {
		padding-bottom: 0.25rem;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 0.15rem;
    font-size: 1.25rem;
	}

	a.footer-link {
		padding-bottom: 0.25rem;
    color: color(black, base);
    text-decoration: none;
    font-size: 1.25rem;
    &:hover {
      text-decoration: underline;
    }
	}

	.footer-info-box {
		@media (min-width: theme("screens.lg")) {
			width: 33%;
		}

		.logo-container {
			display: flex;
			align-items: flex-start;
			padding-bottom: 2rem;
			gap: 2rem;

			.footer-content-image {
				margin-top: 8px;

				img {
					max-width: none;
				}
			}

			.title3 {
				margin-bottom: 0.5rem;
			}

      .p4 {
        padding-bottom: 2rem;
      }

			.underline-link {
				padding-top: 0.5rem;
			}
		}

		.disclaimer {
			line-height: 1.2rem;
			padding-top: 2rem;
		}
	}

	.bottom-row {
    border-top: 1px solid color(accent, lightGray);

    padding-top: 2rem;

    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    
		@media (min-width: theme("screens.lg")) {
			flex-direction: row;
		}

		.socials {
			margin-bottom: 2.5rem;
			gap: 1rem;
      @media (max-width: $mobile) {
        justify-content: center;
      }
      display: flex;

			/* @media (min-width: theme("screens.lg")) {
				order: 2;
				margin-bottom: 0;
			} */
			a img {
				min-width: 15px;
			}
		}

		.links-container {
      text-align: center;
			@media (min-width: theme("screens.lg")) {
        display: flex;
				order: 1;
				justify-content: flex-start;
        align-items: center;
				flex-direction: row;
			}

			.links-row {
        display: inline-block;

        &:not(:last-child) {
          border-right: 1px solid color(accent, lightGray);
        }
        &:not(:first-child) {
          padding: 0 1rem;
        }
        &:first-child {
          padding-right: 1rem;
        }
        font-size: 1rem;
        &> a {
          color: color(black, base);
          text-decoration: none;
        }

        @media (max-width: $mobile) {
          &.last,
          &.text {
            border: none;
          }
        }

				@media (max-width: theme("screens.lg")) {
					padding-bottom: 0.25rem;
				}
			}
		}
	}
}

.footer-top {
  @media (max-width: $mobile) {
    display: block;
  }
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 2rem;

  .left {
    flex-basis: 75%;
    border-right: 1px solid color(accent, lightGray);
    padding: 0 2rem;

    @media (max-width: $mobile) {
      border-bottom: 1px solid color(accent, lightGray);
      border-right: none;
      margin-bottom: 3rem;
      padding: 0 0 3rem;
    }
  }

  .right {
    flex-basis: 33%;
    padding: 0 2rem;

    @media (max-width: $mobile) {
    	padding: 0;
    }

    .footer-info-box {
      width: 100%;
    }
  }
}

.footer-bottom {
  padding: 0 2rem;
}

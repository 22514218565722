/****************************************

CONTAINERS

****************************************/

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0;

	@media (max-width: theme("screens.xl")) {
		padding: 0 $padding--xs;
		//width: calc(100% - ($padding--sm * 2));
	}
}

.container--sm {
	max-width: 875px;
	margin: 0 auto;

	@media (max-width: theme("screens.lg")) {
		padding: 0 $padding--xs;
	}
}

.container--lg {
	max-width: 1400px;
	margin: 0 auto;

	@media (max-width: theme("screens.2xl")) {
		padding: 0 $padding--xs;
	}
}

/****************************************

LAYOUTS

****************************************/

.layout-container,
.layout-content {
	width: 100%;
}

.column-layout {
	display: flex;
	justify-content: space-between;

	@media (max-width: $mobile) {
		flex-direction: column;
	}

	.col {
		// defaults to 50/50
		width: calc((100% - #{$padding--lg}) / 2);

		@media (max-width: theme("screens.xl")) {
			width: calc((100% - #{$padding--sm}) / 2);
		}

		@media (max-width: $mobile) {
			width: 100%;
		}

		&--two-thirds {
			width: calc((100% * 2 / 3) - #{$padding--lg});

			@media (max-width: theme("screens.xl")) {
				width: calc((100% * 2 / 3) - #{$padding--sm});
			}

			@media (max-width: $mobile) {
				width: 100%;
			}
		}

		&--third {
			width: calc((100% - #{$padding--lg}) * 1 / 3);

			@media (max-width: $mobile) {
				width: 100%;
				margin-bottom: $padding--xs;
			}
		}
	}

	&.sm-gap {
		.col {
			width: calc((100% - #{$padding--sm}) / 2);

			@media (max-width: $mobile) {
				width: 100%;
			}
		}
	}

	&.sticky-right {
		align-items: flex-start;

		.col {
			&:last-of-type {
				position: sticky;
				top: calc(143px + #{$padding--sm}); //change to nav height
			}
		}
	}
}

.card-layout {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: center;

	&.halves {
		.card {
			width: calc((100% - #{$padding--lg}) / 2);
			margin-bottom: $padding--lg;

			@media (max-width: theme("screens.xl")) {
				width: calc((100% - #{$padding--sm}) / 2);
				margin-bottom: $padding--sm;
			}

			@media (max-width: $mobile) {
				width: 100%;
			}

			&:not(:nth-of-type(even)) {
				margin-right: $padding--lg;

				@media (max-width: theme("screens.xl")) {
					margin-right: $padding--sm;
				}

				@media (max-width: $mobile) {
					margin-right: 0;
				}
                           &:last-of-type {
				margin-right: auto;
                            }
			}

			
		}
	}

	&.thirds {
		.card {
			width: calc((100% - (#{$gap} * 4)) / 3);
			margin-bottom: calc(#{$gap} * 2);

			@media (max-width: theme("screens.md")) {
				width: 100%;
			}

			&:not(:nth-of-type(3n + 3)) {
				margin-right: calc(#{$gap} * 2);

				@media (max-width: theme("screens.md")) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&.quarters {
		.card {
			width: calc((100% - calc(#{$gap} * 6)) / 4);
			margin-bottom: calc(#{$gap} * 2);

			@media (max-width: theme("screens.md")) {
				width: 100%;
			}

			&:not(:nth-of-type(4n + 4)) {
				margin-right: calc(#{$gap} * 2);

				@media (max-width: theme("screens.md")) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	&.fifths {
		.card {
			width: calc((100% - calc(#{$gap} * 8)) / 5);
			margin-bottom: calc(#{$gap} * 2);

			@media (max-width: theme("screens.md")) {
				width: 100%;
			}

			&:not(:nth-of-type(5n + 6)) {
				margin-right: calc(#{$gap} * 2);

				@media (max-width: theme("screens.md")) {
					margin-right: 0;
				}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

.paragraph,
.ab7-directory,
.cb33-testimonial-slider,
.cb31-stats {
	scroll-margin-top: 100px;
	scroll-snap-align: none;
	scroll-snap-type: none;
}

.paragraph--type--partners-donors .card-layout {
	justify-content: flex-start;
}

.wrap-layout {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.card,
	.field,
	.group {
		width: calc((100% - #{$padding--sm}) / 2);
		@include elementSpacing;

		@media (max-width: theme("screens.xl")) {
			width: calc((100% - #{$padding--sm}) / 2);
		}

		@media (max-width: $mobile) {
			width: 100%;
		}
	}

	.group {
		.card,
		.field {
			width: 100%;
			margin: 0 0 5px 0;
		}
	}
}

.tbl {
	display: flex;
	flex-direction: column;

	.rw {
		width: 100%;
		display: flex;

		&:not(:last-child) {
			border-bottom: $mainRule;
		}

		.col {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: $padding--xs;

			&:not(:last-child) {
				border-right: $mainRule;
			}
		}
	}
}

/****************************************

Chat Icon

****************************************/
.chat-icon{
  position: fixed;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 100;
}
.cb12-repeater {
	background-image: $grad2;

	.column-layout {
		@include paddingTopBottom;
		align-items: center;

		&:not(:last-child) {
			padding-bottom: 0;
		}

		.col {
			position: relative;

			&:nth-child(1) {
				@media (max-width: $mobile) {
					margin-bottom: $padding--md;
				}
			}
		}
	}

	h2 {
		@media (min-width: $mobile) {
			max-width: $textWrap--md;
		}
	}

	&.left {
		.column-layout:nth-of-type(even) {
			flex-direction: row-reverse;

			@media (max-width: $mobile) {
				flex-direction: column;
			}
		}
	}

	&.right {
		.column-layout:nth-of-type(odd) {
			flex-direction: row-reverse;

			@media (max-width: $mobile) {
				flex-direction: column;
			}
		}
	}

	img,
	svg {
		width: 100%;
		height: auto;
	}

  .pdf-link {
    @include btnStyle;
  }

  .wysiwyg {
		margin-bottom: $padding--xs;

		p a:not(.btn) {
			color: color(black);
			text-decoration-color: color(accent, peach);
		}
	}

	&.charcoal, &.blue {
		.btn, .pdf-link {
			color: color(accent, midnight);
			background-color: color(accent, peach);

			&::after {
				background: radial-gradient(circle closest-side, color(accent, smoke), transparent);
			}
		}

		.wysiwyg p a:not(.btn) {
			color: color(white);
		}

		// Change color for white text
		.white-text a:not(.btn):not(.pdf-link)[target="_blank"]:after {
			filter: invert(100%);
		}
	}

	&.charcoal {
		background-image: none;
		background: color(accent, charcoal);
	}

	&.white {
		background-image: none;
		background: color(white);
	}

	&.offset {
		.column-layout:nth-of-type(1) {
			padding-top: 0;

			@media (max-width: $mobile) {
				padding-top: $padding--lg;
			}

			.col:nth-of-type(2) { 
				margin-top: calc(-1 * $padding--lg);

				@media (max-width: $mobile) {
					margin-top: 0;
				}
			}
		}
	}
}

@media (max-width: $mobile) {
	.cb12-repeater.charcoal + .cb12-repeater.charcoal {
		.column-layout:first-of-type {
			padding-top: 0;
		}
	}
}
